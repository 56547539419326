import React from 'react';
import PropTypes from 'prop-types';

class SuccessModal extends React.Component {
    render() {
        const { title, description, amount, amountLabel, image, starImage, onButtonClick } = this.props;

        return (
            <div style={styles.container}>
                <div style={styles.card}>

                    <img src="path_to_star_image_1" alt="Star" style={styles.star1} />
                    <img src="path_to_star_image_2" alt="Star" style={styles.star2} />

                    <button style={styles.closeButton} aria-label="Close">
                        &times;
                    </button>


                    <h2 style={styles.title}>¡Pago exitoso!</h2>

                    <p style={styles.message}>
                        Tu transacción ha sido exitosa.
                        <br />
                        ¡Gracias por confiar en nosotros!
                    </p>


                    <p style={styles.amount}>Monto Pagado: S/{amount}</p>

                    <img
                        src="/img/success.webp"
                        alt="Payment success"
                        style={styles.image}
                    />
                </div>
            </div>
        );
    }
}

const styles = {
    container: {
        position: "relative",
        margin: "0 auto",
        paddingTop: "30px",
        textAlign: "center",
    },
    card: {
        position: "relative",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "none",
        backgroundColor: "#ffffff",
        textAlign: "center",
        margin: "0 auto",
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    closeButton: {
        position: "absolute",
        top: "10px",
        right: "10px",
        border: "none",
        background: "none",
        fontSize: "20px",
        cursor: "pointer",
    },
    title: {
        color: "#088aff",
        fontSize: "24px",
        marginBottom: "10px",
        fontWeight: "bold",
    },
    message: {
        fontSize: "16px",
        color: "#6c757d",
        marginBottom: "20px",
    },
    amount: {
        fontSize: "18px",
        fontWeight: "bold",
        marginBottom: "20px",
    },
    image: {
        width: "150px",
        marginBottom: "20px",
    },
    star1: {
        position: "absolute",
        top: "-20px",
        left: "-30px",
        width: "50px",
    },
    star2: {
        position: "absolute",
        bottom: "-20px",
        right: "-30px",
        width: "50px",
    },
};

SuccessModal.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    amountLabel: PropTypes.string,
    image: PropTypes.string.isRequired,
    starImage: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
};

SuccessModal.defaultProps = {
    amountLabel: 'Monto Pagado',
};

export default SuccessModal;
