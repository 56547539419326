import React from 'react';
import PropTypes from 'prop-types';

const errorModalContent = {
    error_1: {
        title: "¡Inténtalo de nuevo!",
        description: "¡Oops!, transacción denegada. Por favor, contacta a tu banco e inténtalo de nuevo. ¡Gracias!",
        image: "/img/error.webp",
    },
    error_2: {
        title: "¡Inténtalo de nuevo!",
        description: "¡Oops! Parece que los datos de tu tarjeta son errados. Por favor, verifica la información e inténtalo de nuevo. ¡Gracias por tu paciencia!",
        image: "/img/error.webp",
    },
    error_3: {
        title: "¡Inténtalo de nuevo!",
        description: "¡Oops!, tu saldo es insuficiente. Por favor, verifica tu cuenta y vuelve a intentarlo. ¡Gracias por tu paciencia.",
        image: "/img/error.webp",
    },
    error_4: {
        title: "¡Inténtalo de nuevo!",
        description: "¡Oops! Parece que las compras en línea están temporalmente desactivadas. Inténtalo de nuevo más tarde. ¡Gracias por tu paciencia!",
        image: "/img/error.webp",
    },
    error_5: {
        title: "¡Inténtalo de nuevo!",
        description: "¡Oops!, transacción denegada. Por favor, contacta a tu banco e inténtalo de nuevo. ¡Gracias!",
        image: "/img/error.webp",
    }
};


class ErrorModal extends React.Component {
    render() {
        const { hasButton = false, buttonLabel, onButtonClick, code } = this.props;
        const { title, description, image } = errorModalContent[code];
        return (
            <div style={styles.card}>
                <button style={styles.closeButton} onClick={onButtonClick}>X</button>
                <div style={styles.centerContainer}>
                    <div style={styles.imageContainer}>
                        <img src={image} alt="Illustration" style={styles.image} />
                    </div>
                    <h2 style={styles.title}>{title}</h2>
                    <p style={styles.description}>{description}</p>
                </div>
                {hasButton && (
                    <button style={styles.retryButton} onClick={onButtonClick}>
                        {buttonLabel}
                    </button>
                )}
                <div style={styles.footerContainer}>
                    <div style={styles.footer}>
                        Redireccionado al sitio de Shopstar en <strong> 5 segundos</strong>
                        <span style={styles.clockIcon}>⏰</span>
                    </div>
                    <div style={styles.poweredBy}>Powered by <span style={styles.poweredByBrand}>izi pay</span></div>
                </div>
            </div>
        );
    }
}

const styles = {
    card: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        backgroundColor: '#fff',
        position: 'relative',
        flexDirection: 'column',
        height: 600
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'none',
        border: 'none',
        fontSize: '20px',
        cursor: 'pointer',
    },
    centerContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    imageContainer: {
        marginTop: '30px',
        marginBottom: '20px',
    },
    image: {
        width: '200px',
        height: '200px',
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#333',
        marginTop: 15
    },
    description: {
        fontSize: '16px',
        color: '#666',
        margin: '10px 0',
    },
    retryButton: {
        backgroundColor: '#088aff',
        color: '#fff',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '20px',
    },
    footer: {
        fontSize: '14px',
        color: '#666',
        margin: `0px !important`,
        padding: 0,
        marginBottom: `5px !important`
    },
    clockIcon: {
        marginLeft: '5px',
    },
    footerContainer: {
        marginTop: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    poweredBy: {
        margin: '0px !important',
        marginBottom: `5px !important`,
        fontSize: 11,
        color: '#666 !important',
        textAlign: 'center'
    },
    poweredByBrand: {
        margin: '0px !important',
        fontWeight: 'bold',
        color: '#088aff',
        textAlign: 'center',
        marginTop: 5
    },
};

ErrorModal.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string,
    onButtonClick: PropTypes.func,
};

ErrorModal.defaultProps = {
    buttonLabel: null,
    onButtonClick: () => { },
};

export default ErrorModal;
