import React from "react";
import Summary from '../summary/Summary';
import {
    saveOrderV5,
    saveOrderV10,
    authorizePayment,
    sendEmblueData,
    getDiscount,
    getInstallments,
    getInstallmentsV2,
    getMiles,
    getMilesToken,
    getOauthToken,
    sendHubspotData,
    getIziCardToken,
    getIziPushPaymentToken,
    updatePromos,
    getOrderDetail,
    authorizeIzipayTransaction,
    failIzipayTransaction,
    invalidateOrder,
    captureConfirmIzipayTransaction
} from '../../../../services/Service';
import valid from 'card-validator';
import { IBK_BINES, IBK_DEBIT_BINES, IBK_ONLY_CC_BINES, IBK_ONLY_CC_VISA_AMEX_BINES } from '../../../../data/bines/bines';
import { KEYS } from '../../../../constants/index';
import { Kushki } from '@kushki/js';
import * as $ from 'jquery';
import { DISTRICTS, PROVINCES, DEPARTMENTS } from '../../../../data/ubigeos/ubigeos';
import MilesBenefitsForm from "../miles-benefit-form/MilesBenefitsForm";
import { WalletCartLog } from "../miles-benefit-form/MilesMuscleIntegration";
import { ReactComponent as LogoVisa } from '../../../../assets/visa.svg';
import { ReactComponent as LogoMc } from '../../../../assets/mc.svg';
import { ReactComponent as LogoAmex } from '../../../../assets/amex.svg';
import { ReactComponent as LogoDiners } from '../../../../assets/diners.svg';
import { ReactComponent as IconCard } from '../../../../assets/card.svg';
import { ReactComponent as IconIbk } from '../../../../assets/ibk-ico.svg';
import { ReactComponent as IconUser } from '../../../../assets/user.svg';
import { ReactComponent as IconHelp } from '../../../../assets/help.svg';
import PaymentMethods from "./PaymentMethods";
import ErrorModal from "./izipay/ErrorModal";
import SuccessModal from "./izipay/SuccessModal";

class AddressForm extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        stepNumber: 0,
        type: '4',
        paymentSystem: 2,
        cardNumber: "",
        maskedCardNumber: "",
        cardType: "",
        placeHolder: "",
        placeHolderDocumentNumber: "",
        cvv: "",
        unmaskedCvv: "",
        expirationDate: "",
        year: "0",
        month: "0",
        installments: "1",
        installmentAmount: 0,
        installmentOptions: [],
        cvvLength: 0,
        validCard: '',
        cardLength: 16,
        capture: false,
        isIbkCard: '',
        showIsIbkCardMessage: false,
        orderType: 'boleta',
        entityName: '',
        entityIdentificationNumber: '',
        installmentsList: null,
        loading: false,
        error: "",
        miles: {
            miles: 0,
            total: 0,
            selected: 0,
            exchange: 0,
            change: 0,
            error: false,
            loading: false,
            tokenMiles: ""
        },
        milesToken: null,
        policies: {
            personalData: false,
            terms: false,
            privacy: false,
        },
        errors: {},
        ubigeos: {
            departments: DEPARTMENTS,
            provinces: [],
            districts: []
        },
        billingAddress: 'same',
        department: "0",
        province: "0",
        district: "0",
        addressLine: "",
        addressNumber: "",
        flatNumber: "",
        reference: "",
        postalCode: "",
        countryISO: "PER",
        showPrices: true,
        cardObject: null,
        mscInfo: {
            cart: "",
            points: 0.0,
            cash: 0.0
        },
        equivalentInMiles: null,
        loadingPePromos: false,
        //Promos
        restrictedProducts: [],
        promoProducts: [],

        //Izi
        iziObject: null,
        iziErrorsCard: ['82', '14', '63', 'N7'],
        iziErrorsVarios: ['Varios'],
        iziErrorsFondos: ['51'],
        iziErrorsCompras: ['93', '57'],
        iziOrdeResponseAmount: '',
        cardProvider: 'Izipay',
        iziPagoPushErrorCode: ''
    }

    componentDidMount = async () => {
        this.initProviders();
        this.authenticate();
        this.getMilesToken();
        this.getStepNumber();
        this.clearSession();
        this.loadPromo();
        this.loadCardProvider();
        $('#tooltip-chk').tooltip();
    }

    componentWillReceiveProps = async (newProps) => {
        const { cardProvider } = this.state;
        if (newProps.currentForm === 'payment' && this.props.currentForm !== 'payment') {
            let _context = this;

            if (cardProvider && cardProvider === 'Payu') {
                console.log(cardProvider)
                setTimeout(async () => {
                    _context.loadPayuForms();
                }, 100);
            }
            // this.toggleModal("#errorModalIzipayData", "show");
            // this.toggleModal("#errorModalIzipayDenegada", "show");
            // this.toggleModal("#errorModalIzipaySaldo", "show");
            // this.toggleModal("#errorModalIzipayBoton", "show");
            // this.toggleModal("#errorModalIzipayTiempo", "show");
            // this.toggleModal("#okModalIzipay", "show");
            // this.toggleModal("#loadingModalIziPay", "show");
        }
    }

    clearText = (str) => {
        // Normalize the string to decompose accents
        const normalized = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        // Replace "ñ" with "n" and "Ñ" with "N"
        return normalized.replace(/ñ/g, "n").replace(/Ñ/g, "N");
    }
    //   hacer un loadIZIPUshPayment

    loadIziForms = async (transactionId, orderGroup, saleOrderId, showPrices, ibkCard) => {
        const { iziErrorsCard, iziErrorsVarios, iziErrorsFondos, iziErrorsCompras, type, mscInfo } = this.state;
        const { promo, checkout, user, address } = this.props;
        console.log("🚀 ~ AddressForm ~ loadIziForms= ~ user:", user)

        if (promo)
            return;

        this.toggleModal("#loadingModalIziPay", "hide");
        const currentTimeUnix = Math.floor(Date.now()) * 1000;
        // const transactionId = currentTimeUnix.toString().slice(0, 14);
        const orderNumber = currentTimeUnix.toString().slice(0, 10).toString();
        // const MERCHANT_CODE = '4004345'; // KEYS.iziMerchantCode;
        // const PUBLIC_KEY = 'VErethUtraQuxas57wuMuquprADrAHAb'; //KEYS.iziPublicKey;
        let MERCHANT_CODE = KEYS.iziCardMerchantCode //'4006089'; // KEYS.iziMerchantCode;
        let PUBLIC_KEY = KEYS.iziCardPublicKey //KEYS.iziPublicKey;
        let cardType = '';

        if (type == '2' || type == '4') {
            MERCHANT_CODE = KEYS.iziIbkCardMerchantCode;
            PUBLIC_KEY = KEYS.iziIbkCardPublicKey;
            cardType = 'credit';
        } else if (type == '8') {
            MERCHANT_CODE = KEYS.iziIbkDebitCardMerchantCode;
            PUBLIC_KEY = KEYS.iziIbkDebitCardPublicKey;
            cardType = 'debit';
        }

        let ORDER_AMOUNT = checkout.total.toFixed(2);;
        if (type == '1') {
            ORDER_AMOUNT = checkout.total.toFixed(2); // '1.99'; 
        }
        if (type == '2') {
            ORDER_AMOUNT = this.calculateRestAmount().toFixed(2); // '1.99'; 
        }
        const ORDER_CURRENCY = 'PEN';

        const iziConfig = {
            publicKey: PUBLIC_KEY,
            config: {
                transactionId: transactionId,
                action: 'pay',
                merchantCode: MERCHANT_CODE,
                order: {
                    orderNumber: orderNumber,
                    currency: ORDER_CURRENCY,
                    amount: ORDER_AMOUNT,
                    payMethod: 'CARD',
                    processType: 'PA',
                    merchantBuyerId: 'mc1768',
                    dateTimeTransaction: currentTimeUnix.toString()
                },
                card: {
                    brand: '',
                    pan: '',
                },
                billing: {
                    firstName: this.clearText(user.firstName),
                    lastName: this.clearText(user.lastName),
                    email: this.clearText(user.email),
                    phoneNumber: user.phone,
                    street: 'Lima', //address.addressLine,
                    city: this.clearText(address.department),
                    state: this.clearText(address.province),
                    country: 'PE',
                    postalCode: address.postalCode,
                    document: user.identificationNumber,
                    documentType: user.identificationType.toLowerCase() === 'dni' || user.identificationType.toLowerCase() == '1' ? 'DNI' : 'CE',
                },
                render: {
                    typeForm: 'pop-up',
                    container: '#izi-secure-form',
                    showButtonProcessForm: true
                },
                urlRedirect: 'https://server.punto-web.com/comercio/creceivedemo.asp?p=h1',
                appearance: {
                    logo: 'https://demo-izipay.azureedge.net/test/img/millasb.svg',
                    customize: {
                        visibility: {
                            hideResultScreen: true
                        }
                    }
                },
            },
        };
        console.log("🚀 ~ file: PaymentForm.js:195 ~ AddressForm ~ loadIziForms= ~ iziConfig:", iziConfig)

        const izi = new window['Izipay']({
            publicKey: iziConfig.publicKey,
            config: iziConfig.config,
        });

        let tokenModel = {
            transactionId,
            orderNumber,
            "amount": ORDER_AMOUNT // '1.99'
        }

        let tokenResponse = await getIziCardToken(tokenModel, cardType);
        const token = tokenResponse.data.access_token;
        let _this = this;

        izi &&
            izi.LoadForm({
                authorization: token,
                keyRSA: 'RSA', //KEYS.iziRsa,
                callbackResponse: async (response) => {
                    console.log("Izi Response => ", response);
                    if (response.code) {
                        if (response.code === '00') {
                            _this.setState({
                                approved: true,
                                card: response.response.card
                            });

                            let model = {
                                saleOrderId: saleOrderId,
                                id: transactionId,
                                jsonRequest: JSON.stringify(iziConfig),
                                jsonResponse: JSON.stringify(response),
                                token: token,
                                orderNumber: orderNumber
                            }

                            await authorizeIzipayTransaction(model);

                            _this.setState({
                                iziOrdeResponseAmount: response.response.amount
                            });
                            _this.toggleModal("#okModalIzipay", "show");
                            setTimeout(() => {
                                window.location.href = "/checkout/exito/" + orderGroup.split("-")[0] + "?showPrices=" + showPrices;
                            }, 5000)
                        } else {
                            let failModel = {
                                saleOrderId: saleOrderId,
                                id: transactionId,
                                jsonRequest: JSON.stringify(iziConfig),
                                jsonResponse: JSON.stringify(response),
                                token: token,
                                orderNumber: orderNumber,
                                hasCardInformation: response.response ? true : false
                            }
                            await failIzipayTransaction(failModel);

                            let invalidateModel = {
                                orderGroup: orderGroup,
                                reasonType: "izi-form-error",
                                reason: response.message
                            };
                            await invalidateOrder(invalidateModel);

                            _this.setState({
                                error: response.messageUser
                            });

                            if (response.code === '14' || response.code === '82' || response.code === 'N7' || response.code === '63') {
                                _this.toggleModal("#errorModalIzipayData", "show");
                            } else if (response.code === '51') {
                                _this.toggleModal("#errorModalIzipaySaldo", "show");
                            } else if (response.code === '93' || response.code === '57') {
                                _this.toggleModal("#errorModalIzipayBoton", "show");
                            } else if (response.code === '021') {
                                _this.toggleModal("#errorModalIzipayMissingFields", "show");
                            } else if (response.code === '022') {
                                _this.toggleModal("#errorModalIzipayTimeout", "show");
                            } else {
                                _this.toggleModal("#errorModalIzipayDenegada", "show");
                            }
                        }
                    }
                },
            });

        this.setState({
            iziObject: izi
        });
    }

    loadIziPushPayment = async (transactionId, orderGroup, saleOrderId, showPrices) => {

        const { iziErrorsCard, iziErrorsVarios, iziErrorsFondos, iziErrorsCompras, type, mscInfo } = this.state;
        const { promo, checkout, user, address } = this.props;
        console.log("🚀 ~ AddressForm ~ loadIziPushPaymentForms= ~ user:", user)

        if (promo)
            return;

        this.toggleModal("#loadingModalIziPay", "hide");
        const currentTimeUnix = Math.floor(Date.now()) * 1000;
        // transactionId = 'ff1198e9-291c-4636-9021-4df02ed6c511';
        const orderNumber = currentTimeUnix.toString().slice(0, 10).toString();
        let MERCHANT_CODE = KEYS.iziPushPaymentMerchantCode //'4006089'; // KEYS.iziMerchantCode;
        let PUBLIC_KEY = KEYS.iziPushPaymentPublicKey //KEYS.iziPublicKey;
        let cardType = '';


        let ORDER_AMOUNT = checkout.total.toFixed(2);;
        const ORDER_CURRENCY = 'PEN';

        const iziConfig = {
            publicKey: `9fm4DdLPXOusjLodoRZ7btu7k1JpWjBd`,//PUBLIC_KEY,
            config: {
                transactionId: transactionId,
                action: 'pay',
                merchantCode: 4079497,// MERCHANT_CODE_PAGO_PUSH,
                order: {
                    orderNumber: orderNumber,
                    currency: ORDER_CURRENCY,
                    amount: ORDER_AMOUNT,
                    payMethod: 'PAGO_PUSH',
                    processType: 'AT',
                    merchantBuyerId: 'mc1768',
                    dateTimeTransaction: currentTimeUnix.toString()
                },
                card: {
                    brand: '',
                    pan: '',
                },
                billing: {
                    firstName: this.clearText(user.firstName),
                    lastName: this.clearText(user.lastName),
                    email: this.clearText(user.email),
                    phoneNumber: user.phone,
                    street: 'Lima Peru', //address.addressLine,
                    city: this.clearText(address.department),
                    state: this.clearText(address.province),
                    country: 'PE',
                    postalCode: address.postalCode,
                    document: user.identificationNumber,
                    documentType: user.identificationType.toLowerCase() === 'dni' || user.identificationType.toLowerCase() == '1' ? 'DNI' : 'CE',
                },
                render: {
                    typeForm: 'pop-up',
                    container: '#izi-secure-push-payment-form',
                    showButtonProcessForm: true
                },
                urlRedirect: 'https://server.punto-web.com/comercio/creceivedemo.asp?p=h1',
                appearance: {
                    logo: 'https://demo-izipay.azureedge.net/test/img/millasb.svg',
                    customize: {
                        visibility: {
                            hideResultScreen: true
                        }
                    }
                },
            },
        };
        console.log("🚀 ~ file: PaymentForm.js:195 ~ AddressForm ~ loadIziPushPaymentForms= ~ iziConfig:", iziConfig)

        const izi = new window['Izipay']({
            publicKey: iziConfig.publicKey,
            config: iziConfig.config,
        });

        let tokenModel = {
            transactionId,
            orderNumber,
            "amount": ORDER_AMOUNT // '1.99'
        }

        let tokenResponse = await getIziPushPaymentToken(tokenModel, cardType);
        const token = tokenResponse.data.access_token;
        let _this = this;

        izi &&
            izi.LoadForm({
                authorization: token,
                keyRSA: 'RSA', //KEYS.iziRsa,
                callbackResponse: async (response) => {
                    console.log("Izi Response => ", response);
                    _this.toggleModal("#okModalPagoPushIzipay", "show");
                    if (response.code) {

                        if (response.code === '00') {
                            _this.setState({
                                approved: true,
                                card: response.response.card
                            });

                            let model = {
                                saleOrderId: saleOrderId,
                                id: transactionId,
                                jsonRequest: JSON.stringify(iziConfig),
                                jsonResponse: JSON.stringify(response),
                                token: token,
                                orderNumber: orderNumber
                            }

                            await captureConfirmIzipayTransaction(model);

                            _this.setState({
                                iziOrdeResponseAmount: response.response.amount
                            });
                            _this.toggleModal("#okModalPagoPushIzipay", "show");
                            setTimeout(() => {
                                window.location.href = "/checkout/exito/" + orderGroup.split("-")[0] + "?showPrices=" + showPrices;
                            }, 5000)
                        } else {

                            let failModel = {
                                saleOrderId: saleOrderId,
                                id: transactionId,
                                jsonRequest: JSON.stringify(iziConfig),
                                jsonResponse: JSON.stringify(response),
                                token: token,
                                orderNumber: orderNumber,
                                hasCardInformation: response.response ? true : false
                            }
                            await failIzipayTransaction(failModel);

                            let invalidateModel = {
                                orderGroup: orderGroup,
                                reasonType: "izi-form-error",
                                reason: response.message
                            };
                            await invalidateOrder(invalidateModel);

                            _this.setState({
                                error: response.messageUser
                            });

                            let errorMessage = "";

                            this.setState({
                                iziPagoPushErrorCode: response.code
                            })

                            switch (response.code) {
                                case '14':
                                case '82':
                                case 'N7':
                                case '63':
                                    errorMessage = "Error de datos en la transacción.";
                                    break;
                                case '51':
                                    errorMessage = "Saldo insuficiente.";
                                    break;
                                case '93':
                                case '57':
                                    errorMessage = "Error con el botón de pago.";
                                    break;
                                case '021':
                                    errorMessage = "Faltan campos obligatorios.";
                                    break;
                                case '022':
                                    errorMessage = "Tiempo de espera agotado.";
                                    break;
                                default:
                                    errorMessage = "Transacción denegada.";
                                    break;
                            }

                            console.info(errorMessage);
                            _this.toggleModal("#errorModalPagoPushIzipay", "show");

                        }
                    }
                },
            });

        this.setState({
            iziObject: izi
        });

    }

    loadPayuForms = () => {
        const { promo } = this.props;

        if (promo)
            return;

        const fonts = [
            {
                src: 'https://fonts.googleapis.com/css?family=Source+Code+Pro',
            }
        ]

        const { type } = this.state;
        let key = KEYS.payuApiKey;

        $('#card-number').empty();
        $('#exp-date').empty();

        if (type == '5')
            return;

        if (type == '3' || type == '6') {
            key = KEYS.payuInstallmentsApiKey;
        } else {
            key = KEYS.payuApiKey;
        }

        const formElements = new window.POS.Fields(key, {
            fonts
        })

        const placeholders = {
            cardNumber: 'Ingresa un número de tarjeta',
            expDate: 'MM / YY'
        }

        const style = {
            base: {
                fontWeight: 600,
                height: '40px',
                fontSize: '16px',
                color: '#495057'
            },
        };

        const classes = {
            base: 'payu-input'
        }

        const cardNumber = formElements.create('cardNumber', {
            placeholders,
            classes,
            style
        })
        cardNumber.mount('#card-number');

        const expiry = formElements.create('creditCardExpiry', {
            placeholders,
            classes,
            style
        })
        expiry.mount('#exp-date');

        cardNumber.on('change', (event) => {
            if (!event.bin)
                return;

            let e = {
                target: {
                    value: event.bin
                }
            };

            this.validateCardNumber(e);
            this.getDiscount(e);
            this.validateIbkCardNumber(e);
        });

        cardNumber.on('blur', (event) => {
            if (!event.bin)
                return;

            if (!event.complete) {
                this.toggleFieldError('cardNumber', true);
            } else {
                this.toggleFieldError('cardNumber', false);
            }

            let e = {
                target: {
                    value: event.bin
                }
            };

            this.getDiscount(e);
        });

        expiry.on('blur', (event) => {
            if (!event.complete) {
                this.toggleFieldError('expirationDate', true);
            } else {
                this.toggleFieldError('expirationDate', false);
            }
        });

        this.setState({
            cardObject: cardNumber
        });
    }

    loadPromo = () => {
        const { promo } = this.props;
        if (promo) {
            this.setState({
                showPrices: promo.showPrices
            })
        }
    }

    loadCardProvider = () => {
        const { checkout } = this.props;
        if (checkout) {
            this.setState({
                cardProvider: checkout.cardProvider
            })
        }
    }

    getStepNumber = () => {
        let number = $(".checkout__body__form").length;
        this.setState({
            stepNumber: number + 1
        });
    }

    clearSession = () => {
        window.localStorage.removeItem("__customerId__");
        window.localStorage.removeItem("__code__");
        window.localStorage.removeItem("__authInfo__");

        this.setState({
            type: '4'
        })
    }

    getMilesToken = async () => {
        let result = await getMilesToken();

        if (result.status === 200) {
            this.setState({
                milesToken: result.data
            });
        }
    }

    showMilesLoginForm = () => {
        let src = "";
        const { milesToken } = this.state;

        if (milesToken) {
            src = KEYS.interbankLoginPageV2 + "?client_id=SHOPSTAR&redirect_uri=" + KEYS.redirectUriV2 + "&cancel_uri=" + KEYS.redirectUriV2 + "&scope=login&response_mode=app_message&token=" + milesToken.token.access_token;
        }

        return (<React.Fragment><iframe src={src} frameBorder="0"></iframe></React.Fragment>);
    }

    validateMilesLogin = () => {
        let _context = this;

        var milesLoginWatcher = setInterval(() => {
            let authInfo = JSON.parse(window.localStorage.getItem("__authInfo__"));

            if (authInfo) {
                setTimeout(() => {
                    clearInterval(milesLoginWatcher);
                }, 1000);

                _context.toggleModal("#benefitLoginModal", "hide");

                window.localStorage.setItem("__customerId__", authInfo.customerId);
                window.localStorage.setItem("__code__", authInfo.code);
                window.localStorage.removeItem("__authInfo__");

                _context.authenticate();
            }
        }, 4000);
    }

    initProviders = () => {
        let k = {
            merchantId: KEYS.kushkiId,
            inTestEnvironment: KEYS.isTesting,
            regional: false
        }

        let ki = {
            merchantId: KEYS.kushkiIdInstallments,
            inTestEnvironment: KEYS.isTesting,
            regional: false
        }

        window.kushki = new Kushki(k);
        window.kushkiInstallments = new Kushki(ki);
        window['_fs_namespace'] = window['FS'];
    }

    authenticate = async () => {
        const { checkout, setMiles } = this.props;
        const { miles, type } = this.state;

        let customerId = localStorage.getItem("__customerId__");
        let code = localStorage.getItem("__code__");

        let _miles = miles;
        _miles.loading = true;

        if (!customerId)
            return;

        this.setState({ type: '2', miles: _miles })

        let model = {
            customerId: customerId,
            checkOutId: checkout.draftId,
            code: code
        }

        let tokenResponse = await getOauthToken(model);

        if (tokenResponse.status !== 200) {
            //console.error("Error getting miles token");
            return;
        }

        let milesModel = {
            checkOutId: checkout.draftId,
            tokenMiles: tokenResponse.data.access_token,
            customerId: customerId,
        }

        let milesResponse = await getMiles(milesModel);

        // if (milesResponse.status !== 200 && milesResponse.status !== 201) {
        //     _miles.error = true;
        //     this.setState({
        //         miles: _miles
        //     });
        //     return;
        // }

        // milesResponse['data'] = { "description": "USTED DISPONE DE 5,046 MILLAS BENEFIT", "miles": 5046.0, "exchange": 151.380 }

        if (milesResponse.status === 200) {
            let selectedMiles = milesResponse.data.miles;
            const exchange = milesResponse.data.miles / milesResponse.data.exchange;
            const totalOrder = checkout.total;

            if (selectedMiles > 0)
                await this.getInterbankDiscount()

            if (milesResponse.data.exchange > totalOrder) {
                selectedMiles = this.calculateSelectedMilesAmount(milesResponse.data);
            }
            _miles = {
                total: milesResponse.data.miles,
                exchange: milesResponse.data.exchange,
                selected: Math.ceil(selectedMiles),
                change: exchange
            };

            _miles.loading = false;
            _miles.tokenMiles = tokenResponse.data.access_token;
            _miles.customerId = tokenResponse.data.customerId;
            setMiles(_miles, type);
            this.setState({ miles: _miles });
        } else {
            this.clearSession()
        }
    }

    getInterbankDiscount = async () => {
        const { type } = this.state;
        const { checkout, loadCheckout } = this.props;

        let model = {};

        if (type == '2') {
            let bin = "998422";
            model = {
                bin: bin,
                installments: 1,
                referenceValue: checkout.total,
                paymentSystem: 1,
                customerId: window.localStorage.getItem("__customerId__")
            }

            await getDiscount(checkout.draftId, model);
            await loadCheckout();
            this.getInstallments(checkout.draftId);
        }
    }

    calculateSelectedMilesAmount = (miles) => {
        const { checkout } = this.props;
        const exchange = miles.miles / miles.exchange;
        const totalOrder = checkout.total;

        let milesAmount = checkout.total * exchange;
        let milesDiscount = miles / exchange;

        if (milesDiscount < totalOrder) {
            milesAmount += 1;
        }

        return milesAmount;
    }

    handleChangeValue = (field, event) => {
        let value = event.target.value;

        if (field === 'unmaskedCvv' || field === 'placeHolderDocumentNumber') {
            var re = /^[0-9]*$/;
            if (!re.test(value))
                return;
        }

        if (!value || value === "") {
            this.toggleFieldError(field, true)
        } else {
            this.toggleFieldError(field, false)
        }

        this.setState({
            [field]: value
        });
    }

    handleChangeMiles = (event) => {
        const { miles, type } = this.state;
        const { checkout, setMiles } = this.props;
        let _miles = miles;
        let value = event.target.value;

        if (value > 0) {
            if (value > _miles.total) {
                value = _miles.total;
            }

            if ((value / miles.change) > checkout.total) {
                value = Math.ceil(checkout.total * miles.change);
            }
        } else {
            value = 0;
        }

        _miles.selected = value;

        setMiles(_miles, type);
        this.setState({
            miles: _miles
        })
    }

    calculateTotalIbk = () => {
        const { ibkPrices, checkout, start } = this.props;
        //console.log("🚀 ~ file: Summary.js:279 ~ Summary ~ ibkPrices:", ibkPrices)

        let items = [];

        if (!checkout) {
            items = start ? start.items : [];
        } else {
            items = checkout ? checkout.items : [];
        }

        let total = 0;
        // ibkPrices.forEach(el => {
        //     total += el.price;
        // });
        if (checkout && checkout.externalCart) {
            items.forEach((p, j) => {
                let externalCart = checkout.externalCart;

                if (ibkPrices) {
                    let ibkPrice = ibkPrices.find((ibk) => {
                        return p.productId === ibk.id
                    });

                    if (ibkPrice && ibkPrice.price >= 0)
                        total += ibkPrice.price * p.quantity;
                    else
                        total += p.price * p.quantity;
                }
            })
        }

        return total + checkout.shippingCost;
    }

    onSelectPaymentMethod = async (type) => {
        const { miles } = this.state;
        const { setMiles, checkout, loadCheckout } = this.props;

        let _context = this;
        let _checkout = checkout;

        let model = {
            bin: '',
            installments: 1,
            referenceValue: checkout.total,
            paymentSystem: 202,
            customerId: ''
        }

        let customer = localStorage.getItem("__customerId__");
        if (type === '2') {
            if (!customer) {
                $("#benefitLoginModal").modal("show");
                this.validateMilesLogin();

                return;
            } else {
                this.setState({ type });
            }
        } else if (type == '1') {
            model.bin = '411111';
            model.paymentSystem = 1;

            $("#changePaymentMethodLoaderModal").modal("show");
            await getDiscount(_checkout.draftId, model);
            await loadCheckout();
            $("#changePaymentMethodLoaderModal").modal("hide");

            this.setState({ type });
        } else if (type == '4') {
            model.bin = '998422';
            model.paymentSystem = 1;

            $("#changePaymentMethodLoaderModal").modal("show");
            await getDiscount(_checkout.draftId, model);
            await loadCheckout();
            $("#changePaymentMethodLoaderModal").modal("hide");

            this.setState({ type });
        } else if (type == '8') {
            model.bin = '421355';
            model.paymentSystem = 1;

            $("#changePaymentMethodLoaderModal").modal("show");
            await getDiscount(_checkout.draftId, model);
            await loadCheckout();
            $("#changePaymentMethodLoaderModal").modal("hide");

            this.setState({ type });
        } else if (type === '5' || type === '6') {
            $("#changePaymentMethodLoaderModal").modal("show");

            let paymentSystem = 202;

            if (type == '6')
                paymentSystem = 203;

            let model = {
                bin: '',
                installments: 1,
                referenceValue: checkout.total,
                paymentSystem: paymentSystem,
                customerId: ''
            }

            await getDiscount(checkout.draftId, model);
            await loadCheckout();

            this.setState({ type });
            $("#changePaymentMethodLoaderModal").modal("hide");

            if (type === '6') {
                this.validateQrPromo()
            }
        } else {
            if (type == '7') {
                $("#changePaymentMethodLoaderModal").modal("show");

                model.bin = '411111';
                model.paymentSystem = 1;

                if (_checkout.freeInstallmentsPriceType === 'IbkPrice') {
                    // total = this.calculateTotalIbk();
                    model.bin = '998422';
                } else {

                }

                await getDiscount(_checkout.draftId, model);
                await loadCheckout();

                this.getFreeInstallments();

            }
            this.setState({ type, installments: 1 });
            $("#changePaymentMethodLoaderModal").modal("hide");
        }

        setMiles(miles, type);

        setTimeout(() => {
            if (type !== '1' && type !== '2') {
                _context.loadPayuForms();
            }
        }, 500)
    }

    validateQrPromo = () => {
        const { checkout } = this.props;

        let restrictedProducts = [];
        let _context = this;
        let previousPromos = JSON.parse(checkout.promos || null) || [];

        checkout.externalCart.forEach((el, i) => {
            let cart = JSON.parse(el.externalJsonShipping);
            let items = cart.items;
            let promoId = "";
            let promoName = "";

            if (cart && cart.ratesAndBenefitsData) {
                let ratesAndBenefits = cart.ratesAndBenefitsData;
                let ratesAndBenefitsIdentifiers = ratesAndBenefits.rateAndBenefitsIdentifiers || [];

                let promo = ratesAndBenefitsIdentifiers.find((rb) => {
                    return rb.name.indexOf("PLIN") > -1;
                })

                promoId = promo ? promo.id : "";
                promoName = promo ? promo.name : "";
            }

            let promo = previousPromos.find(p => p.Name === promoName);
            let promoProducts = [];

            if (promoId !== "") {
                items.forEach((item) => {
                    let tags = item.priceTags || [];
                    let previousPurchase = promo ? promo.Items.find(i => i.Sku === item.id) : null;

                    let quantity = item.quantity;

                    let chkItem = checkout.items.find(c => c.sku === item.id);

                    if (chkItem)
                        quantity = chkItem.quantity;

                    if (previousPurchase)
                        quantity += previousPurchase.Quantity;

                    let promoTag = tags.find(t => t.identifier === promoId);
                    if (promoTag && quantity > 5) {
                        item.promoName = promoName;
                        restrictedProducts.push(item);
                    }

                    promoProducts.push({
                        Quantity: quantity,
                        Sku: item.id
                    })
                });

                if (promo) {
                    promo.Items = promoProducts;
                } else {
                    previousPromos.push({
                        Name: promoName,
                        UseDate: new Date(),
                        Items: promoProducts
                    });
                }
            }
        })

        if (restrictedProducts.length > 0) {
            setTimeout(() => {
                $("#promoPlinErrorModal").modal("show");
            }, 500)

            this.setState({ restrictedProducts });
        }

        this.setState({ promoProducts: previousPromos });

        //console.log(restrictedProducts);
        //console.log(previousPromos);
    }

    getFreeInstallments = () => {
        const { checkout } = this.props;

        const freeInstallments = checkout.freeInstallments;
        let total = checkout.total;

        let installmentsList = freeInstallments.map((el) => {
            return {
                installments: el,
                amount: total / el
            }
        });

        this.setState({
            installmentsList
        })
    }

    closeValidateMilesModal = () => {
        $("#milesModal").modal("hide");
    }

    formatMoney = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' }).format(amount) : 'S/ 0.00';
    }

    formatNumber = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE').format(amount) : '0';
    }

    validateCardNumber = (e) => {
        const { checkout } = this.props;

        let cardNumber = e.target.value;

        var re = /^[0-9]*$/;
        if (!re.test(cardNumber))
            return;

        let card = valid.number(cardNumber);
        let cards = {
            visa: "Visa",
            amex: "American Express",
            diners: "Diners",
            mastercard: "Mastercard"
        }
        let validCard = "";
        let cardType = "";
        let cardLength = 16;
        let installmentOptions = [];
        let cvvLength = 0;

        validCard = card.isValid ? 'valid' : '';

        if (card.card) {
            cardType = card.card.type;
            cardLength = Math.max.apply(null, card.card.lengths);

            if (checkout && checkout.shippingData) {
                let paymentData = checkout.shippingData.paymentData.installmentOptions.filter((el, index) => {
                    return el.paymentName === cards[card.card.type];
                })[0];
                if (paymentData) {
                    installmentOptions = paymentData.installments;
                }
            }
        }

        if (card.code && card.code.name === 'CVV') {
            cvvLength = card.code.size;
        } else {
            cvvLength = 3;
        }

        this.toggleFieldError('cardNumber', false)

        this.setState({
            cardNumber: cardNumber,
            validCard: validCard,
            cardType: cardType,
            cardLength: cardLength,
            installmentOptions: installmentOptions,
            cvvLength: cvvLength
        });
    }

    validateIbkCardNumber = (e) => {
        const { type } = this.state;

        const urlParams = new URLSearchParams(window.location.search);
        // //console.log('urlParams: ', urlParams);
        // const amex = urlParams.get('amex');
        // //console.log('amexParam: ', amex);

        let cardNumber = e.target.value;

        let bines = [];

        if (type == 3 || type == 7) {
            // if (amex && amex != 'null' && amex == 'true') {
            //     //console.log(typeof amex);
            //     bines = IBK_ONLY_CC_VISA_AMEX_BINES;
            // } else {
            bines = IBK_ONLY_CC_VISA_AMEX_BINES;
            // }
        } else {
            bines = IBK_BINES;
        }


        if (type != '3' && type != '7') {
            bines = bines.concat(IBK_DEBIT_BINES);
        }

        let ibkCard = 'invalid';
        bines.forEach(bin => {
            if (cardNumber.indexOf(bin) > -1) {
                ibkCard = 'valid';
            }
        });

        // if (type == 1) {
        //     if (ibkCard === 'valid') {
        //         this.setState({
        //             type: '4'
        //         })
        //     }
        // } else if (type == 4) {
        //     if (ibkCard === 'invalid') {
        //         this.setState({
        //             type: '1'
        //         })
        //     }
        // }

        if (ibkCard === 'invalid') {
            $('#installmentsList').collapse('hide');
            this.setState({
                installments: 1
            })
        }

        this.setState({ isIbkCard: ibkCard });
    }

    getDiscount = async (e) => {
        const { type } = this.state;
        const { checkout, loadCheckout } = this.props;

        let cards = {
            visa: "Visa",
            amex: "American Express",
            diners: "Diners",
            mastercard: "Mastercard"
        }
        let model = {};

        if (e.target.value.length == 6 || (e.type === "blur" && e.target.value.length >= 6)) {

            let bin = e.target.value.substring(0, 6);
            model = {
                bin: bin,
                installments: 1,
                referenceValue: checkout.total,
                paymentSystem: 1,
                customerId: window.localStorage.getItem("__customerId__")
            }

            if (type == '2' || type == '7') {

            } else {
                let checkoutId = checkout.draftId;

                if (type != '6') {
                    await getDiscount(checkout.draftId, model);
                    await loadCheckout();
                }

                this.getInstallments(checkoutId);
            }
        }
    }

    showInstallmentsText = () => {
        const { checkout } = this.props;

        let fashionPromo = true;

        let externalCart = checkout.externalCart;
        externalCart.forEach((el) => {
            let cart = JSON.parse(el.externalJsonShipping);
            let cartItems = cart.items;
            cartItems.forEach((item) => {
                let categoryTree = item.productCategories;
                let isFashion = false;
                for (let k in categoryTree) {
                    if (
                        (categoryTree[k].toLowerCase().indexOf('electrodomesticos') > -1 && (item.seller === 'shpseller232' || item.seller === 'shpseller369' || item.seller === 'shpseller195')) ||
                        (categoryTree[k].toLowerCase().indexOf('electrohogar') > -1 && item.seller === 'shpseller371') ||
                        (
                            item.seller === 'shpseller21' || item.seller === 'shpseller354' || item.seller === 'shpseller125' ||
                            item.seller === 'supermallpe' || item.seller === 'shpseller240' || item.seller === 'shpseller380' ||
                            item.seller === 'shpseller346' || item.seller === 'shpseller329' || item.seller === 'pesonyb2c' || item.seller === 'shpseller370' ||
                            item.seller === 'shpseller705' || item.seller === 'renzocosta'
                        ) ||
                        (
                            (
                                categoryTree[k].toLowerCase().indexOf('colchones') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('camas box tarima') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('juegos de dormitorio') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('sala') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('camas funcionales') > -1
                            ) && item.seller === 'drimerqa'
                        ) ||
                        (
                            (
                                categoryTree[k].toLowerCase().indexOf('laptops') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('impresoras') > -1
                            ) && item.seller === 'shpseller163'
                        ) ||
                        (
                            (
                                categoryTree[k].toLowerCase().indexOf('audio') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('smartwatch') > -1
                            ) && item.seller === 'shpseller269'
                        ) ||
                        (
                            (
                                categoryTree[k].toLowerCase().indexOf('electrodomesticos') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('vineras') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('hornos microondas') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('lavavajillas') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('climatizacion') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('congeladoras') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('frigobares') > -1
                            ) && item.seller === 'electroluxpe'
                        ) ||
                        (
                            (
                                categoryTree[k].toLowerCase().indexOf('telefonia') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('camaras') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('computo') > -1
                            ) && item.seller === 'shpseller25'
                        )) {
                        isFashion = true;
                        break;
                    }
                }

                if (!isFashion)
                    fashionPromo = false;
            });
        });

        if (fashionPromo)
            return (
                <p>Paga en 3, 6, 12, 18, 24 o 36 cuotas con una tasa preferencial.</p>
            );

        return (
            <p>Paga en 6, 12, 18, 24 o 36 cuotas con una tasa preferencial.</p>
        );
    }

    getInstallments = async (checkoutId) => {
        const { checkout } = this.props;
        const { type } = this.state;

        let fashionPromo = true;

        let externalCart = checkout.externalCart;
        let freeInstallments = checkout.freeInstallments;
        let installments = [];

        externalCart.forEach((el) => {
            let cart = JSON.parse(el.externalJsonShipping);
            let cartItems = cart.items;
            cartItems.forEach((item) => {
                let categoryTree = item.productCategories;
                let isFashion = false;
                for (let k in categoryTree) {
                    if (
                        (categoryTree[k].toLowerCase().indexOf('electrodomesticos') > -1 && (item.seller === 'shpseller232' || item.seller === 'shpseller369' || item.seller === 'shpseller195')) ||
                        (categoryTree[k].toLowerCase().indexOf('electrohogar') > -1 && item.seller === 'shpseller371') ||
                        (
                            item.seller === 'shpseller21' || item.seller === 'shpseller354' || item.seller === 'shpseller125' ||
                            item.seller === 'supermallpe' || item.seller === 'shpseller240' || item.seller === 'shpseller380' ||
                            item.seller === 'shpseller346' || item.seller === 'shpseller329' || item.seller === 'pesonyb2c' || item.seller === 'shpseller370' ||
                            item.seller === 'shpseller705' || item.seller === 'renzocosta'
                        ) ||
                        (
                            (
                                categoryTree[k].toLowerCase().indexOf('colchones') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('camas box tarima') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('juegos de dormitorio') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('sala') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('camas funcionales') > -1
                            ) && item.seller === 'drimerqa'
                        ) ||
                        (
                            (
                                categoryTree[k].toLowerCase().indexOf('laptops') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('impresoras') > -1
                            ) && item.seller === 'shpseller163'
                        ) ||
                        (
                            (
                                categoryTree[k].toLowerCase().indexOf('audio') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('smartwatch') > -1
                            ) && item.seller === 'shpseller269'
                        ) ||
                        (
                            (
                                categoryTree[k].toLowerCase().indexOf('electrodomesticos') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('vineras') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('hornos microondas') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('lavavajillas') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('climatizacion') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('congeladoras') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('frigobares') > -1
                            ) && item.seller === 'electroluxpe'
                        ) ||
                        (
                            (
                                categoryTree[k].toLowerCase().indexOf('telefonia') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('camaras') > -1 ||
                                categoryTree[k].toLowerCase().indexOf('computo') > -1
                            ) && item.seller === 'shpseller25'
                        )) {
                        isFashion = true;
                        break;
                    }
                }

                if (!isFashion)
                    fashionPromo = false;
            });
        });

        if (fashionPromo) {
            installments.push({
                amount: checkout.total / 3,
                installments: 3
            });
        }

        if (type == '3') {
            let installmentsResponse = await getInstallments(checkoutId);

            if (installmentsResponse.error)
                return;

            installments = installments.concat(installmentsResponse.data);
        } else {
            installments = freeInstallments.map((el) => {
                return {
                    installments: el,
                    amount: checkout.total / el
                }
            })
        }

        this.setState({
            installmentsList: installments
        });
    }

    onCvvBlur = () => {
        const { cvv, unmaskedCvv } = this.state;
        let _cvv = cvv;
        let _unmaskedCvv = unmaskedCvv;

        if (_unmaskedCvv.indexOf('*') > -1)
            return;

        if (_unmaskedCvv.length < 3)
            return;

        _cvv = _unmaskedCvv;
        this.setState({
            cvv: _cvv
        });
        clearTimeout(window.maskCvvTimeout);

        window.maskCvvTimeout = setTimeout(() => {
            _unmaskedCvv = _unmaskedCvv.replace(/./g, '*');
            this.setState({
                unmaskedCvv: _unmaskedCvv
            });
        }, 2000);
    }

    getMilesDiscount = () => {
        return 0;
    }

    getOrderTotal = () => {
        const { checkout } = this.props;

        return checkout.total;
    }

    renderInstallmentsList = () => {
        const { checkout } = this.props;
        const { installments, installmentsList, isIbkCard, errors } = this.state;

        if (!installmentsList) {
            return (
                <div className="installments-list">
                    <label>Dividir en cuotas</label>
                    <div className="card">
                        <div className="card-header" id="installmentsListHeading">
                            <h2 className="mb-0">
                                <button className="btn btn-link btn-block text-left collapsed" disabled={true}>
                                    Pago único de {this.formatMoney(checkout.total)}
                                    {/* {installments.loading &&
                                        <i className="loading-icon fas fa-spinner fa-pulse"></i>
                                    }
                                    {!installments.loading &&
                                        <i className="loading-icon fas fa-caret-down"></i>
                                    } */}
                                </button>
                            </h2>
                        </div>
                    </div>
                </div>
            );
        }

        let selectedInstallments = installments;
        let installmentsControl = "";
        let installmentsText = "";
        let installmentsAmounts = [];

        if (checkout.freeInstallments && checkout.freeInstallments.length > 0)
            installmentsAmounts = installmentsList.filter((el) => checkout.freeInstallments.indexOf(el.installments) < 0);
        else
            installmentsAmounts = installmentsList;

        if ((selectedInstallments == 1 || selectedInstallments == 0) && checkout) {
            installmentsText = "Pago único de " + this.formatMoney(checkout.total);
        } else {
            let installmentInfo = installmentsAmounts.filter((el, i) => {
                return selectedInstallments == el.installments;
            })
            if (installmentInfo.length > 0) {
                installmentsText = selectedInstallments + " cuotas de " + this.formatMoney(installmentInfo[0].amount);
            }
        }

        installmentsControl = (
            <div className={isIbkCard == 'valid' ? 'installments-list valid-ibk-card' : 'installments-list '}>
                <label>Dividir en cuotas</label>
                <div className={'card ' + (errors.installments ? 'border-danger' : '')}>
                    <div className="card-header" id="installmentsListHeading">
                        <h2 className="mb-0">
                            <button className={'btn btn-link btn-block text-left collapsed'}
                                type="button"
                                data-toggle="collapse"
                                data-target="#installmentsList"
                                aria-expanded="false"
                                aria-controls="collapseOne"
                                disabled={this.state.isIbkCard !== 'valid'}>
                                {installmentsText}
                                {isIbkCard == 'valid' &&
                                    <i className="fas"></i>
                                }
                            </button>
                        </h2>
                    </div>

                    <div id="installmentsList" className="collapse" aria-labelledby="installmentsListHeading">
                        <ul className="list-group list-group-flush">
                            {
                                installmentsAmounts.map((el, i) => {
                                    return (
                                        <li className="list-group-item" key={'installment-' + i}>
                                            <a role="button" onClick={(e) => { this.setInstallments(el) }}>
                                                <span>{el.installments} cuotas de {this.formatMoney(el.amount)}&nbsp;
                                                    <span style={{ display: 'none' }} className="installmentsTotal">(Total {this.formatMoney(el.amount * el.installments)})</span></span>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="installments-disaclaimer">El fraccionamiento de tus cuotas lo visualizarás una semana después de tu compra, te solicitamos no modificar las cuotas de esta compra en tu App.</div>
            </div>
        );

        return <React.Fragment>{installmentsControl}</React.Fragment>;
    }

    renderFreeInstallmentsList = () => {
        const { checkout } = this.props;
        const { installments, installmentsList, isIbkCard, errors } = this.state;

        ////console.log(get.draft.total, payment);
        let selectedInstallments = installments;
        let installmentsControl = "";
        let installmentsText = "";
        let installmentsAmounts = installmentsList;

        if ((selectedInstallments == 1 || selectedInstallments == 0) && checkout) {
            installmentsText = "Pago único de " + this.formatMoney(checkout.total);
        } else {
            let installmentInfo = installmentsAmounts.filter((el, i) => {
                return selectedInstallments == el.installments;
            })
            if (installmentInfo.length > 0) {
                installmentsText = selectedInstallments + " cuotas de " + this.formatMoney(installmentInfo[0].amount);
            }
        }

        installmentsControl = (
            <div className={isIbkCard == 'valid' ? 'installments-list valid-ibk-card' : 'installments-list '}>
                <label>Dividir en cuotas</label>
                <div className={'card ' + (errors.installments ? 'border-danger' : '')}>
                    <div className="card-header" id="installmentsListHeading">
                        <h2 className="mb-0">
                            <button className={'btn btn-link btn-block text-left collapsed'}
                                type="button"
                                data-toggle="collapse"
                                data-target="#installmentsList"
                                aria-expanded="false"
                                aria-controls="collapseOne">
                                {installmentsText}
                                {isIbkCard == 'valid' &&
                                    <i className="fas"></i>
                                }
                            </button>
                        </h2>
                    </div>

                    <div id="installmentsList" className="collapse" aria-labelledby="installmentsListHeading">
                        <ul className="list-group list-group-flush">
                            {
                                installmentsAmounts.map((el, i) => {
                                    return (
                                        <li className="list-group-item" key={'installment-' + i}>
                                            <a role="button" onClick={(e) => { this.setInstallments(el) }}>
                                                <span>{el.installments} cuotas de {this.formatMoney(el.amount)}&nbsp;
                                                    <span>Sin intereses</span>
                                                    <span style={{ display: 'none' }} className="installmentsTotal">(Total {this.formatMoney(el.amount * el.installments)})</span></span>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="installments-disaclaimer">El fraccionamiento de tus cuotas lo visualizarás hasta una semana después de tu compra, te solicitamos no modificar las cuotas de esta compra en tu App.</div>
            </div>
        );

        return <React.Fragment>{installmentsControl}</React.Fragment>;
    }

    setInstallments = (item) => {
        $(".installments-list .card").removeClass("error");
        $('#installmentsList').collapse('hide');

        this.toggleFieldError('installments', false);

        this.setState({
            installments: item.installments,
            installmentAmount: item.amount
        });
    }

    toggleModal = (modal, action) => {
        $(modal).modal(action);
    }

    validState = () => {
        const { checkout } = this.props;
        let state = this.state;
        let valid = true;
        let errors = {};
        //, "year", "month"
        let requiredFields = ["cardNumber", "placeHolder", "cvv", "unmaskedCvv", "placeHolderDocumentNumber"];

        if (checkout.total === 0) {
            requiredFields = [];
        }

        if (state.billingAddress === "diff") {
            requiredFields = requiredFields.concat(["department", "province", "district", "addressLine", "addressNumber", "postalCode"]);
        }

        if (state.orderType === "factura") {
            requiredFields = requiredFields.concat(["entityName", "entityIdentificationNumber"]);
        }

        for (let field in state) {
            if (requiredFields.indexOf(field) > -1 && (!state[field] || state[field] === "" || state[field] === "0")) {
                errors[field] = true;
                valid = false;
            }
            if (state.orderType === "factura") {
                if (field === "entityIdentificationNumber" && (state[field].length !== 11 || isNaN(state[field]))) {
                    errors[field] = true;
                    valid = false;
                }
            }
        }

        this.setState({ errors });
        return valid;
    }

    sendHubspotData = async () => {
        const { user, checkout } = this.props;

        let categories = [];

        let externalCart = checkout.externalCart;
        externalCart.forEach((el) => {
            let cart = JSON.parse(el.externalJsonShipping);
            let cartItems = cart.items;
            cartItems.forEach((item) => {
                let categoryTree = item.productCategories;
                let categoryName = '';

                for (let k in categoryTree) {
                    categoryName = categoryTree[k];
                }

                categories.push(categoryName);
            });
        });

        let model = {
            firstname: user.firstName,
            lastname: user.lastName,
            email: user.email,
            phone: user.phone,
            mobilephone: user.phone,
            dni: user.identificationNumber,
            orden_de_compra: '123123123123',
            cuenta_maestra: 'Buyers',
            propietario_asignado___atencion_al_cliente: 'hola@shopstar.com.pe',
            categoria_de_interes: categories.join(',')
        }

        await sendHubspotData(user.email, model);
    }

    handleSubmitPayment = async () => {
        const { checkout, user, address, stores, shippingItems, gateway, saveDataLayerEvent, promo } = this.props;
        const { orderType, miles, isIbkCard, policies, billingAddress, type, installments, entityName, entityIdentificationNumber, showPrices, cardObject, mscInfo, restrictedProducts, promoProducts, cardProvider } = this.state;

        let payment = {
            ...this.state
        };
        payment.cardObject = null;

        let gatewayName = gateway.name;
        let gatewaySessionId = gateway.sessionId;
        let model = {};

        let milesDiscount = this.calculateMilesDiscount();
        let customerId = window.localStorage.getItem("__customerId__");
        let ip = window.localStorage.getItem("__ipAddress__");
        let _stores = stores;
        let _address = address;

        let uncheckedPolicies = false;

        if (!policies.terms) {
            uncheckedPolicies = true;
            this.toggleFieldError("terms", true);
        }

        if (!policies.personalData) {
            uncheckedPolicies = true;
            this.toggleFieldError("personalData", true);
        }

        if (uncheckedPolicies)
            return;

        let cashMilesLeft = this.state.mscInfo.cash;//this.calculateRestAmount();

        if (type == '2') {
            if (mscInfo.cart === '') {
                this.setState({
                    error: "Por ahora no podemos canjear tus millas. Por favor intenta con otro método de pago."
                });

                setTimeout(() => {
                    this.toggleModal("#basicErrorModal", "show");
                }, 500);

                return;
            }
        }

        if (type == '2' && cashMilesLeft == 0) {

        } else {
            if (checkout.total === 0 && !promo) {
                this.setState({
                    error: "Algo ha ido mal. El monto de tus productos debe ser mayor al monto de tu cupón aplicado."
                });

                setTimeout(() => {
                    this.toggleModal("#basicErrorModal", "show");
                }, 100);

                return;
            }
        }
        
        if (type == '3' || type == '7') {
            if (installments <= 1) {
                this.toggleFieldError('installments', true);
                return
            }

            if (isIbkCard !== 'valid') {
                // this.toggleFieldError('installments', true);
                return;
            }
        }

        let usePayu = true;
        if (cardProvider === 'Payu') {
            let valid = this.validState();

            if (type == '2' && cashMilesLeft == 0) {
                usePayu = false;
            } else if (type == '5' || type == '6') {
                usePayu = false;
            } else {
                if (!valid)
                    return;
            }
        } else {
            if (type != 3 && type != 7) {
                usePayu = false;
            }
        }

        if (type == '6') {
            if (restrictedProducts && restrictedProducts.length > 0) {
                setTimeout(() => {
                    $("#promoPlinErrorModal").modal("show");
                }, 500)
                return;
            }
        }

        if (promo) {
            usePayu = false;
        }

        //Payu call
        if (usePayu) {
            this.toggleModal("#loadingModal", "show");

            const additionalData = {
                holder_name: payment.placeHolder,
                identity_document: {
                    type: 'CC',
                    number: payment.placeHolderDocumentNumber
                }
            }
            const result = await window.POS.createToken(cardObject, {
                additionalData,
                environment: KEYS.payuEnv
            })
            if (!result.error) {
                let payuResponse = JSON.parse(result);

                gatewaySessionId = payuResponse.token;
                payment.cardNumber = this.maskPayuCreditCardNumber(payuResponse.bin_number, payuResponse.last_4_digits);
                let expirationDateArray = payuResponse.expiration_date.split('/');

                if (expirationDateArray && expirationDateArray.length === 2) {
                    this.setState({
                        year: `20${expirationDateArray[1]}`,
                        month: expirationDateArray[0],
                        maskedCardNumber: payment.cardNumber,
                    });

                    payment.year = '****'; //expirationDateArray[1];
                    payment.month = '**'; //expirationDateArray[0];
                }
            } else {
                this.setState({
                    error: "Algo ha ido mal. Por favor revisa que los datos de tu tarjeta sean los correctos."
                });

                setTimeout(() => {
                    this.toggleModal("#loadingModal", "hide");
                    this.toggleModal("#basicErrorModal", "show");
                }, 500);

                return;
            }
        } else {
            if (type == '1' || type == '2' || type == '4' || type == '8') {
                this.toggleModal("#loadingModalIziPay", "show");
            }
            else if (type == '55') {
                // this.toggleModal("#loadingModalIziPushPay", "show");
            }
            else {
                this.toggleModal("#loadingModal", "show");
            }
            // gatewaySessionId = '';
        }

        saveDataLayerEvent({
            'event': 'virtualEvent',
            'category': 'Checkout - Metodo de pago',
            'action': 'Seleccionar boton',
            'label': 'Comprar ahora'
        });

        let storedData = localStorage.getItem("__state__");
        if (storedData) {
            storedData = JSON.parse(storedData);
            _stores = storedData.stores;
        }

        window.localStorage.setItem("__checkoutId__", checkout.draftId);

        payment.amountToAuthorize = this.getOrderTotal();
        payment.creditCardTokenId = gatewaySessionId;

        if (payment.type == 2 && customerId) {
            if (milesDiscount < checkout.total && isIbkCard !== "valid") {
                setTimeout(() => {
                    this.toggleModal("#loadingModal", "hide");
                    $("#loadingPaymentModal").modal("hide");
                    $("#benefitCardErrorModal").modal("show");
                }, 500);
                return;
            }
            model.tokenMiles = miles.tokenMiles;
            payment.miles = {
                value: miles.selected,
                customerId: miles.customerId
            }

            if (payment.equivalentInMiles) {
                payment.equivalentInMiles.cartId = mscInfo.cart;
            
                const muscleCartItems = localStorage.getItem("muscleCartItems");
                if (muscleCartItems) {
                    try {
                        payment.equivalentInMiles.items = JSON.parse(muscleCartItems);
                    } catch (error) {
                        console.error("Failed to parse muscleCartItems:", error);
                        payment.equivalentInMiles.items = []; 
                    }
                } else {
                    payment.equivalentInMiles.items = [];
                }
              
                localStorage.removeItem("muscleCartItems");
            }
                

        } else {
            payment.miles = undefined;
        }

        if (payment.type == 3) {
            payment.paymentMethodType = "payment-installments";
        } else if (payment.type == 5) {
            payment.paymentMethodType = "payment-pago-efectivo";
            payment.paymentSystem = 202;
            payment.paymentSystemName = "Pago Efectivo";
            payment.groupName = "custom202PaymentGroup";
        } else if (payment.type == 6) {
            payment.paymentMethodType = "payment-qr";
            payment.paymentSystem = 203;
            payment.paymentSystemName = "QR Plin";
            payment.groupName = "custom203PaymentGroup";
        } else if (payment.type == 4) {
            payment.paymentMethodType = "payment-ibk-card";
        } else if (payment.type == 2) {
            payment.paymentMethodType = "payment-ibk-card";
        } else if (payment.type == 7) {
            payment.paymentMethodType = "payment-msi";
        } else if (payment.type == 8) {
            payment.paymentMethodType = "payment-ibk-debit-card";
        } else if (payment.type == 55) {
            payment.paymentMethodType = "payment-push-app";
        } else {
            payment.paymentMethodType = "default";
        }

        if (gatewayName === 'payu') {
            if (payment.cardType === 'visa') {
                payment.cardType = 'VISA';
            } else if (payment.cardType === 'mastercard') {
                payment.cardType = 'MASTERCARD';
            } else if (payment.cardType === 'diners-club') {
                payment.cardType = 'DINERS';
            } else if (payment.cardType === 'american-express') {
                payment.cardType = 'AMEX';
            }
        } else {
            payment.errors.cardNumber = false;
            payment.errors.placeHolder = false;
            payment.errors.placeHolderDocumentNumber = false;
            payment.errors.expirationDate = false;
            payment.errors.unmaskedCvv = false;
            payment.errors.cardNumber = false;
            payment.installmentsList = [];
        }

        _address.ubigeos = null;

        model.checkOutId = checkout.draftId;
        model.orderType = orderType;
        model.entityName = entityName;
        model.entityIdentificationNumber = entityIdentificationNumber;
        model.shipping = address;

        //Payu hub data
        model.deviceSessionId = gateway.sessionId;
        model.userAgent = navigator.userAgent;
        model.ipAddress = ip;

        if (billingAddress === 'diff') {
            model.billing = {
                department: this.state.department,
                province: this.state.province,
                district: this.state.district,
                addressLine: this.state.addressLine,
                addressNumber: this.state.addressNumber,
                flatNumber: this.state.flatNumber,
                reference: this.state.reference,
                postalCode: this.state.postalCode,
                countryISO: "PER",
            };
        } else {
            model.billing = address;
        }

        model.payment = payment;
        model.cardProvider = cardProvider;
        model.shipping.firstName = user.firstName;
        model.shipping.lastName = user.lastName;
        model.shipping.phone = user.phone;
        model.shipping.cellPhone = user.phone;

        model.authorizePersonalData = policies.privacy;
        // model.capture = capture;

        if (policies.privacy) {
            let emblueModel = {
                "eventName": "shopstar",
                "email": user.email,
                "attributes": {
                    "nombre": user.firstName,
                    "apellidos": user.lastName,
                    "telefono": user.phone,
                    "DNI": user.identificationNumber,
                    "AceptoTerminosCondiciones": policies.terms,
                    "aceptoTerminosPagoCanje": policies.personalData,
                    "aceptoUsoDeDatos": policies.privacy,
                    "tag": "Checkout Millas"
                }
            };

            sendEmblueData(emblueModel);
        }

        let items = [];

        _stores.forEach((el, i) => {
            el.products.forEach((p, j) => {
                let item = null;

                if (el.shippingMethod === "express") {
                    if (!el.selectedDeliverySla) {
                        let sla = p.logisticInfo.slas.filter((s, k) => {
                            return s.deliveryChannel === "delivery" && s.id === 'Entrega hoy shopstar';
                        });

                        sla = sla.length > 0 ? sla[0] : null;
                        el.selectedDeliverySla = sla;
                    }
                    let vtxJson = checkout.externalCart.filter((s, k) => {
                        return s.externalSellerId === el.store;
                    });
                    let shippingPrice = 0;
                    let productSla = [];

                    if (vtxJson.length > 0) {
                        vtxJson = vtxJson[0];
                        let vtxShipping = JSON.parse(vtxJson.externalJsonShipping);
                        if (vtxShipping && vtxShipping.shippingData && vtxShipping.shippingData.logisticsInfo) {
                            vtxShipping.shippingData.logisticsInfo.forEach((lInfo, l) => {
                                let slas = lInfo.slas;
                                if (p.requestIndex === lInfo.itemIndex || p.id === lInfo.itemId) {
                                    productSla = slas.filter((s, k) => {
                                        return p.logisticInfo.selectedSla === s.id;
                                    });

                                    if (productSla.length > 0)
                                        shippingPrice = productSla[0].price / 100;
                                }
                            })
                        }
                    }

                    item = {
                        "addressId": address.addressId, //"addressId": `addressd-${i}-${j}`,
                        "itemIndex": p.requestIndex || j,
                        "itemId": p.id,
                        "selectedDeliveryChannel": el.shippingMethod,
                        "selectedSla": el.selectedDeliverySla.id,
                        "price": el.selectedDeliverySla.price / 100,
                        "deliveryWindow": el.deliveryDeliveryWindow,
                        "shippingEstimate": el.selectedDeliverySla.shippingEstimate,
                        "lockTTL": el.selectedDeliverySla.lockTTL,
                        "shippingEstimateDate": el.selectedDeliverySla.shippingEstimateDate,
                        "addressLine": address.addressLine,
                        "addressNumber": address.addressNumber,
                        "reference": address.reference,
                        "countryISO": "PER",
                        "postalCode": address.postalCode,
                        "department": address.department,
                        "province": address.province,
                        "district": address.district
                    }
                } else if (el.shippingMethod === "delivery") {
                    if (!el.selectedDeliverySla) {
                        let sla = p.logisticInfo.slas.filter((s, k) => {
                            return s.deliveryChannel === "delivery" && s.id !== 'Entrega hoy shopstar';
                        });

                        sla = sla.length > 0 ? sla[0] : null;
                        el.selectedDeliverySla = sla;
                    }
                    let vtxJson = checkout.externalCart.filter((s, k) => {
                        return s.externalSellerId === el.store;
                    });
                    let shippingPrice = 0;
                    let productSla = [];

                    if (vtxJson.length > 0) {
                        vtxJson = vtxJson[0];
                        let vtxShipping = JSON.parse(vtxJson.externalJsonShipping);
                        if (vtxShipping && vtxShipping.shippingData && vtxShipping.shippingData.logisticsInfo) {
                            vtxShipping.shippingData.logisticsInfo.forEach((lInfo, l) => {
                                let slas = lInfo.slas;
                                if (p.requestIndex === lInfo.itemIndex || p.id === lInfo.itemId) {
                                    productSla = slas.filter((s, k) => {
                                        return p.logisticInfo.selectedSla === s.id;
                                    });

                                    if (productSla.length > 0)
                                        shippingPrice = productSla[0].price / 100;
                                }
                            })
                        }
                    }

                    item = {
                        "addressId": address.addressId, //"addressId": `addressd-${i}-${j}`,
                        "itemIndex": p.requestIndex || j,
                        "itemId": p.id,
                        "selectedDeliveryChannel": el.shippingMethod,
                        "selectedSla": el.selectedDeliverySla.id,
                        "price": shippingPrice, //el.selectedDeliverySla.price / 100,
                        "deliveryWindow": el.deliveryDeliveryWindow,
                        "shippingEstimate": el.selectedDeliverySla.shippingEstimate,
                        "lockTTL": el.selectedDeliverySla.lockTTL,
                        "shippingEstimateDate": el.selectedDeliverySla.shippingEstimateDate,
                        "addressLine": address.addressLine,
                        "addressNumber": address.addressNumber,
                        "reference": address.reference,
                        "countryISO": "PER",
                        "postalCode": address.postalCode,
                        "department": address.department,
                        "province": address.province,
                        "district": address.district
                    }
                } else if (el.shippingMethod === "pickup-in-point") {
                    let storeInfo = shippingItems.items.find((shippingItem, k) => {
                        return shippingItem.productId === p.id;
                    });

                    item = {
                        "addressId": storeInfo ? storeInfo.storeInfo.addressId : `addressw-${i}-${j}`,
                        "itemIndex": p.requestIndex || j,
                        "itemId": p.id,
                        "selectedDeliveryChannel": el.shippingMethod,
                        "selectedSla": el.selectedSla.id,
                        "price": el.selectedSla.price / 100,
                        "deliveryWindow": el.deliveryWindow,
                        "shippingEstimate": el.selectedSla.shippingEstimate,
                        "lockTTL": el.selectedSla.lockTTL,
                        "shippingEstimateDate": el.selectedSla.shippingEstimateDate,
                        "storePickUpAddress": el.address,
                        "addressLine": storeInfo.storeInfo.street,
                        "addressNumber": storeInfo.storeInfo.number,
                        "reference": storeInfo.storeInfo.reference,
                        "countryISO": storeInfo.storeInfo.country,
                        "postalCode": storeInfo.storeInfo.postalCode,
                        "department": storeInfo.storeInfo.state,
                        "province": storeInfo.storeInfo.city,
                        "district": storeInfo.storeInfo.neighborhood,
                        "geoCoordinates": storeInfo.storeInfo.geoCoordinates,
                        "alternativePickupPersonFullName": el.alternativePickupPersonFullName,
                        "alternativePickupPersonIdentificationNumber": el.alternativePickupPersonIdentificationNumber,
                        "alternativePickupPersonIdentificationType": el.alternativePickupPersonIdentificationType,
                        "alternativePickupPersonPhone": el.alternativePickupPersonPhone,
                    }
                }

                item.sellerId = el.store;
                items.push(item);
            })
        })

        this.setState({
            loading: true
        })

        model.shippingMethods = items;
        model.hiddenPrices = !showPrices;
        // await this.sendHubspotData();


        let externalCart = checkout.externalCart;
        
        let warrantyAttachmentList = [];

        if (externalCart && externalCart.length > 0) {
            externalCart.forEach((el, i) => {
                let cart = JSON.parse(el.externalJsonShipping);
                console.log("cart encontrado");
                console.log(cart);
                
                if (cart) {

                    let items = cart.items;
                    items.forEach((item, j) => {
                        console.log("item encontrado");
                        console.log(item);

                        if (item.attachments.length > 0) {
                            console.log("Este producto es la garantía");
                            let attachment = item.attachments[0];
                            let warrantyAttachment = {};
                            console.log("attachment encontrado");
                            console.log(attachment);

                            let attachmentInfo = attachment.content;
                            console.log("attachmentInfo encontrado");
                            console.log(attachmentInfo);

                            warrantyAttachment.warrantyId = item.id;
                            warrantyAttachment.WarrantyProductId = item.productId; //Este es el producto garantía
                            warrantyAttachment.warrantyName = item.name;

                            warrantyAttachment.relatedName = attachmentInfo.producto;
                            warrantyAttachment.relatedSku = attachmentInfo.sku;
                            warrantyAttachmentList.push(warrantyAttachment);
                        }
                    })
                    
                }
            })

            //Volver a recorrer externalCart si se enciontró la garantía, para completar información de productCategories y 
            if (warrantyAttachmentList.length > 0) {
                externalCart.forEach((el, i) => {
                    let cart = JSON.parse(el.externalJsonShipping);
                    if (cart) {
    
                        let items = cart.items;
                        items.forEach((item, j) => {
                            warrantyAttachmentList.forEach((warranty, i) => {
                                if (warranty.relatedSku == item.id) {
                                    warranty.relatedCategoryIds = item.productCategoryIds
                                    warranty.relatedBranchName = item.additionalInfo.brandName
                                }
                            })
                        })
                    }
                })
            }
        }

        console.log("warrantyAttachments encontrados")
        console.log(warrantyAttachmentList);
        
        model.warranties = warrantyAttachmentList

        console.log("================ORDER REQUEST================")
        console.log(model);
        //return;

        if (promo) {
            model.linkId = promo.id;
        }

        // Test push payment
        // if(type == '55') {

        //     this.loadIziPushPayment(null, null, null, showPrices)
        //     return;
        // }

        let responseOrder = await saveOrderV10(model);

        if (responseOrder.status !== 200 && responseOrder.status !== 201) {
            let message = responseOrder.data;
            if (responseOrder.status === 500) {
                message = "Ocurrió un error, inténtalo nuevamente"
            }

            this.setState({
                loading: false,
                error: message
            });

            this.toggleModal("#loadingModal", "hide");
            this.toggleModal("#loadingModalIziPay", "hide");
            this.toggleModal("#loadingModalIziPushPay", "hide");

            setTimeout(() => {
                this.toggleModal("#errorModal", "show");
            }, 100);
            return;
        }

        if (payment.type == 2 && customerId) {
            //Todo: Exec when create order is ssuccess
            const response = await WalletCartLog(this.state.mscInfo.cart, this.state.mscInfo);
        }

        let orderInfo = responseOrder.data;
        const urlParams = new URLSearchParams(window.location.search);
        const version = urlParams.get('version');

        if (payment.type == 6) {
            await updatePromos({
                email: user.email,
                promos: promoProducts
            })
        }

        if (cardProvider === 'Payu') {
            window.location.href = "/checkout/exito/" + orderInfo.orders[0].orderNumber.split("-")[0] + "?showPrices=" + showPrices;
        } else {

            if (type == '1' || type == '2' || type == '4' || type == '8') {
              
                if (type == '2' && cashMilesLeft === 0) {
                    window.location.href = "/checkout/exito/" + orderInfo.orders[0].orderNumber.split("-")[0] + "?showPrices=" + showPrices;
                    return;
                }

                if (orderInfo.orders[0].orderNumber != '') {
                    const orderDetail = await getOrderDetail(null, orderInfo.orders[0].orderNumber)
                    const transactionId = orderDetail.data.orders[0].transaction.transactionId;
                    let ibkCard = type == '4' || type == '8'
                    this.toggleModal("#loadingModal", "hide");
                    this.loadIziForms(transactionId, orderInfo.orders[0].orderNumber, orderDetail.data.orders[0].saleOrderId, showPrices, ibkCard);

                    return;
                }
            }
            else if (type == '55') {

                if (orderInfo.orders[0].orderNumber != '') {
                    const orderDetail = await getOrderDetail(null, orderInfo.orders[0].orderNumber)
                    const transactionId = orderDetail.data.orders[0].transaction.transactionId;

                    this.toggleModal("#loadingModal", "hide");

                    this.loadIziPushPayment(transactionId, orderInfo.orders[0].orderNumber, orderDetail.data.orders[0].saleOrderId, showPrices)

                    return;
                }

            }
            else {
                window.location.href = "/checkout/exito/" + orderInfo.orders[0].orderNumber.split("-")[0] + "?showPrices=" + showPrices;
            }
        }
    }

    authorize = async (model) => {
        const { showPrices } = this.state;
        var authResponse = await authorizePayment(model);

        if (authResponse.status !== 200 && authResponse.status !== 201) {
            this.setState({
                loading: false,
                error: authResponse.data
            })

            this.toggleModal("#loadingModal", "hide");
            this.toggleModal("#errorModal", "show");
            return;
        }

        window.location.href = "/checkout/exito/" + authResponse.data.orderGroup + "?showPrices=" + showPrices;
    }

    changeMilesAmount = (e, op) => {
        const { miles, type } = this.state;
        const { checkout, setMiles } = this.props;
        const change = miles.change;

        let amount = op * 100;
        let selected = parseFloat(miles.selected) + amount;

        if (selected > 0) {
            if (selected > miles.total) {
                selected = miles.total;
            }

            if ((selected / change) > checkout.total) {
                selected = Math.ceil(checkout.total * change);
            }
        } else {
            selected = 0;
        }

        let _miles = miles;
        _miles.selected = Math.round(selected);

        setMiles(_miles, type);
        this.setState({ miles: _miles });
    }

    calculateExchange = (e) => {
        const { miles } = this.state;
        let _miles = miles;

        _miles.exchange = miles.selected / miles.change;
        this.setState({ miles: _miles });
    }

    calculateMilesDiscount = () => {
        const { miles } = this.state;
        const { checkout } = this.props;

        let discount = miles.selected / miles.change;

        if (discount > checkout.total) {
            discount = checkout.total;
        }

        return discount
    }

    calculateRestAmount = () => {
        const { checkout } = this.props;
        const { miles, type, mscInfo } = this.state;

        let _context = this;

        if (type != 2) {
            return checkout.total;
        }
        //let rest = (checkout.total - (miles.selected / miles.change));
        //let rest = (checkout.total - mscInfo.cash);
        let rest = mscInfo.cash;

        if (rest < 0) {
            rest = 0;
        }

        if (rest > 0) {
            // let cardElement = document.getElementById("card-number");
            // if (!cardElement) {
            //     setTimeout(() => {
            //         _context.loadPayuForms();
            //     }, 500)
            // }
        }
        //console.log('calculateRestAmount', typeof rest);
        return rest;
    }

    calculateOrderTotalMiles = () => {
        let ibkTotal = this.calculateTotalIbk();

        var total = ibkTotal * 33.33333;

        return Math.ceil(total);
    }

    onChangePolicyCheck = (e) => {
        const { policies, errors } = this.state;
        const name = e.target.getAttribute("name");

        let p = policies;

        p[name] = !p[name];

        this.toggleFieldError(name, false);
        this.setState({ policies: p })
    }

    toggleFieldError = (name, value) => {
        const { errors } = this.state;
        let _errors = errors;

        _errors[name] = value;
        this.setState({ errors: _errors });
    }

    loadProvinces = (e) => {
        const { ubigeos, department } = this.state;

        let provinces = PROVINCES[e.target.value];

        if (provinces && provinces.length > 0) {
            ubigeos.provinces = provinces;

            this.setState({ ubigeos: ubigeos });
        }
    }

    loadDistricts = (e) => {
        const { ubigeos, province } = this.state;

        let districts = DISTRICTS[e.target.value];

        if (districts && districts.length > 0) {
            ubigeos.districts = districts;

            this.setState({ ubigeos: ubigeos });
        }
    }

    setPostalCode = (e) => {
        const { ubigeos } = this.state;
        let value = e.target.value;
        let district = ubigeos.districts.filter((e, i) => { return e.id == value })[0];

        this.setState({
            postalCode: district.ubigeo
        });
    }

    handleChangeBillingAddress = (event, type) => {
        this.setState({
            billingAddress: type
        });
    }

    handleOrderType = () => {
        var orderType = "";
        if (this.state.orderType == 'boleta') {
            orderType = 'factura';
        } else {
            orderType = 'boleta';
        }

        this.setState({ orderType: orderType })
    }

    maskCreditCardNumber = () => {
        const { cardNumber } = this.state;
        if (!cardNumber || cardNumber === '')
            return '';

        return '************' + cardNumber.substring(cardNumber.length - 4, cardNumber.length);
    }

    maskPayuCreditCardNumber = (bin, lastDigits) => {
        const { cardNumber, cardLength } = this.state;

        // let mask = new Array(cardLength - bin.length - lastDigits.length).join('*');

        if (!cardNumber || cardNumber === '')
            return '';

        return `${bin}******${lastDigits}`;
    }

    setMilesChild = (miles) => {
        this.setState({
            miles: miles
        });
    }

    setMscInfo = (cartId, points, cash) => {
        let _context = this;

        this.setState({
            mscInfo: {
                cart: cartId,
                points: points,
                cash: cash
            }
        }, () => {

            if (cash > 0) {
                // let cardElement = document.getElementById("card-number");
                // if (!cardElement) {
                //     //console.log("El elemento cardElement no está creado");
                //     setTimeout(() => {
                //         _context.loadPayuForms();
                //     }, 500)
                // } else {
                //     //console.log("El elemento cardElement está creado");
                //     _context.loadPayuForms();
                // }
            }
        });
    }

    setEquivalentInMiles = (miles) => {
        this.setState({
            equivalentInMiles: miles
        });
    }

    renderInvoiceSelector = () => {
        const { checkout } = this.props;

        let carts = checkout.externalCart;
        let brands = ["DENASCAR", "BUTACA PERU", "MAR DE COPAS"];
        let allowInvoice = true;

        carts.forEach(cart => {
            let jsonShipping = JSON.parse(cart.externalJsonShipping);
            if (jsonShipping != null) {
                let items = jsonShipping.items;
                if (items && items.length > 0) {
                    items.forEach(item => {
                        let brand = item.additionalInfo ? item.additionalInfo.brandName : "";
                        brand = brand ? brand.toUpperCase() : "";

                        if (brands.indexOf(brand) > -1)
                            allowInvoice = false;
                    });
                }
            }
        });

        if (allowInvoice)
            return (
                <div className="form-group mt-4">
                    <label>Comprobante</label>
                    <select className="form-control full-story-hidden" value={this.state.orderType} onChange={(e) => this.handleChangeValue('orderType', e)}>
                        <option value="boleta">Boleta</option>
                        <option value="factura">Factura</option>
                    </select>
                </div>
            );

        return (
            ""
        );
    }

    calculateTotalIbk = () => {
        const { ibkPrices, checkout, start } = this.props;

        let items = [];

        if (!checkout) {
            items = start ? start.items : [];
        } else {
            items = checkout ? checkout.items : [];
        }

        let total = 0;

        if (checkout && checkout.externalCart) {
            items.forEach((p, j) => {
                let externalCart = checkout.externalCart;
                if (ibkPrices) {
                    let ibkPrice = ibkPrices.find((ibk) => {
                        return p.productId === ibk.id
                    });

                    //console.log(ibkPrice)
                    if (ibkPrice && ibkPrice.price > 0)
                        total += ibkPrice.price;
                    else
                        total += p.price;
                }
            })
        }

        return total + checkout.shippingCost;
    }

    refresh = () => {
        window.location.reload();
    }

    render() {
        const {
            type,
            error,
            miles,
            maskedCardNumber,
            cardType,
            policies,
            billingAddress,
            errors,
            ubigeos,
            isIbkCard,
            orderType,
            installmentsList,
            installmentAmount,
            installments,
            iziOrdeResponseAmount,
            cardProvider } = this.state;

        const { currentForm,
            redirectInterbankLogin,
            user,
            checkout,
            start,
            enableBenefits,
            totalIbk,
            totalCheckout,
            promo } = this.props;

        const urlParams = new URLSearchParams(window.location.search);
        const forceBenefits = urlParams.get('forceBenefits');

        // //console.log('Stores:', this.props.stores)

        const freeInstallments = checkout.freeInstallments;

        return (
            <React.Fragment>
                <div className="checkout__body__form border-bottom-0">
                    <h3 className="checkout__body__form__title">
                        <span className={"number " + (currentForm === 'payment' ? 'purple' : '')}>4</span>
                        Revisa y paga
                    </h3>
                    {
                        (currentForm === 'payment') &&
                        <React.Fragment>
                            <div className="payment-summary">
                                <Summary
                                    checkout={checkout}
                                    start={start}
                                    promo={promo}
                                    totalCheckout={totalCheckout}
                                    miles={miles} />
                            </div>
                            <div className="card step-form">
                                <div className="card-body">
                                    <div className="payment-methods">
                                        <PaymentMethods
                                            checkout={checkout}
                                            installments={installments}
                                            installmentsList={installmentsList}
                                            isIbkCard={isIbkCard}
                                            promo={promo}
                                            start={start}
                                            totalCheckout={totalCheckout}
                                            miles={miles}
                                            onSelectPaymentMethod={this.onSelectPaymentMethod}
                                            type={type}
                                            errors={errors}
                                            freeInstallments={freeInstallments} />


                                        {
                                            (type == 2 && miles.loading) &&
                                            <p className="section-title-text text-center mt-3">
                                                <i className="fas fa-circle-notch fa-spin mr-2"></i>
                                                <b>Cargando Millas...</b>
                                            </p>
                                        }
                                        {
                                            (type != 2 || !miles.loading) &&
                                            <React.Fragment>
                                                {/* Credit Card */}
                                                {
                                                    ((type != 1 || type == 2 || type == 4 || type == 3) && checkout.total > 0) &&
                                                    <h4 className="section-title">Completa tu pago</h4>
                                                }
                                                {/* Benefits */}
                                                {
                                                    (type == 2) &&
                                                    <React.Fragment>
                                                        <MilesBenefitsForm
                                                            checkout={checkout} totalIbk={totalIbk} type={type}
                                                            user={user} miles={this.state.miles} setMiles={this.props.setMiles} setMilesChild={this.setMilesChild}
                                                            setMscInfo={this.setMscInfo} setEquivalentInMiles={this.setEquivalentInMiles} ibkPrices={this.props.ibkPrices} />
                                                    </React.Fragment>
                                                }
                                                {
                                                    (cardProvider === 'Payu' && (checkout.total > 0 || !promo)) &&
                                                    <React.Fragment>
                                                        {
                                                            ((type != 2 && type != 5 && type != 6 && type != 4)) &&
                                                            <div className="credit-card">
                                                                <div id="izi-secure-form"></div>
                                                                <div id="izi-secure-push-payment-form"></div>
                                                                <div className={"form-group cc-input " + this.state.validCard + (errors.cardNumber ? ' invalid' : '')}>
                                                                    <label>
                                                                        Tarjeta de crédito o débito
                                                                    </label>
                                                                    <div className="cards">
                                                                        {
                                                                            (type == 3 || type == 7) &&
                                                                            <React.Fragment>
                                                                                <LogoVisa></LogoVisa>
                                                                                <LogoAmex></LogoAmex>
                                                                            </React.Fragment>
                                                                        }

                                                                    </div>
                                                                    <div id="card-number" className="form-control full-story-hidden payu-item"></div>
                                                                    {
                                                                        (this.state.isValid)
                                                                    }
                                                                    {
                                                                        (cardType === '') && <IconCard className="card-icon"></IconCard>
                                                                    }
                                                                    {
                                                                        (cardType === 'visa') && <LogoVisa className="card-logo"></LogoVisa>
                                                                    }
                                                                    {
                                                                        (cardType === 'american-express') && <LogoAmex className="card-logo"></LogoAmex>
                                                                    }
                                                                    {
                                                                        (cardType === 'diners-club') && <LogoDiners className="card-logo"></LogoDiners>
                                                                    }
                                                                    {
                                                                        (cardType === 'mastercard') && <LogoMc className="card-logo"></LogoMc>
                                                                    }
                                                                    {
                                                                        (isIbkCard === 'valid') &&
                                                                        <i className="ibk-card-ico">
                                                                            <IconIbk></IconIbk>
                                                                        </i>
                                                                    }
                                                                </div>
                                                                {
                                                                    ((type == '3' || type == '7') && isIbkCard === 'invalid') &&
                                                                    <div className="invalid-ibk-card">
                                                                        <small className="text-danger">
                                                                            Para dividir su pago en cuotas, debe usar una tarjeta de cr&eacute;dito Interbank.
                                                                        </small>
                                                                    </div>
                                                                }
                                                                <div className={'form-group ' + (errors.placeHolder ? ' invalid' : '')}>
                                                                    <label>Titular de la tarjeta</label>
                                                                    <input type="text" className="form-control full-story-hidden"
                                                                        placeholder="Ingresa el nombre del titular"
                                                                        value={this.state.placeHolder}
                                                                        onChange={(e) => this.handleChangeValue('placeHolder', e)} />
                                                                </div>
                                                                {
                                                                    (type == 3 || true) &&
                                                                    <div className={'form-group ' + (errors.placeHolderDocumentNumber ? ' invalid' : '')}>
                                                                        <label>Documento de identidad</label>
                                                                        <input type="text" placeholder="Ingresa tu n&uacute;mero de documento" className="form-control" required name="placeHolderDocumentNumber" value={this.state.placeHolderDocumentNumber} onChange={(e) => this.handleChangeValue('placeHolderDocumentNumber', e)} />
                                                                    </div>
                                                                }
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className={'form-group ' + (errors.expirationDate ? ' invalid' : '')}>
                                                                            <label>Fecha de vencimiento</label>
                                                                            <div id="exp-date" className="form-control full-story-hidden payu-item"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className={"form-group" + (errors.unmaskedCvv ? ' invalid' : '')}>
                                                                            <label>
                                                                                Ingresa CVV
                                                                                <span className="cvv-help">
                                                                                    <IconHelp></IconHelp>
                                                                                    <div className="cvv-help__content">Es un código de verificación de 3 o 4 dígitos ubicado en el reverso de la tarjeta de crédito o débito.</div>
                                                                                </span>
                                                                            </label>
                                                                            <input type="text" className="form-control full-story-hidden"
                                                                                placeholder=""
                                                                                value={this.state.unmaskedCvv}
                                                                                maxLength={4}
                                                                                onChange={(e) => this.handleChangeValue('unmaskedCvv', e)}
                                                                                onBlur={(e) => this.onCvvBlur()} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </React.Fragment>
                                                }
                                                {
                                                    (cardProvider !== 'Payu' && (checkout.total > 0 || !promo)) &&
                                                    <React.Fragment>
                                                        {
                                                            ((type == 3 || type == 7)) &&
                                                            <div className="credit-card">
                                                                <div id="izi-secure-form"></div>
                                                                <div className={"form-group cc-input " + this.state.validCard + (errors.cardNumber ? ' invalid' : '')}>
                                                                    <label>
                                                                        Tarjeta de crédito o débito
                                                                    </label>
                                                                    <div className="cards">
                                                                        {
                                                                            (type == 3 || type == 7) &&
                                                                            <React.Fragment>
                                                                                <LogoVisa></LogoVisa>
                                                                                <LogoAmex></LogoAmex>
                                                                            </React.Fragment>
                                                                        }

                                                                    </div>
                                                                    <div id="card-number" className="form-control full-story-hidden payu-item"></div>
                                                                    {
                                                                        (this.state.isValid)
                                                                    }
                                                                    {
                                                                        (cardType === '') && <IconCard className="card-icon"></IconCard>
                                                                    }
                                                                    {
                                                                        (cardType === 'visa') && <LogoVisa className="card-logo"></LogoVisa>
                                                                    }
                                                                    {
                                                                        (cardType === 'american-express') && <LogoAmex className="card-logo"></LogoAmex>
                                                                    }
                                                                    {
                                                                        (cardType === 'diners-club') && <LogoDiners className="card-logo"></LogoDiners>
                                                                    }
                                                                    {
                                                                        (cardType === 'mastercard') && <LogoMc className="card-logo"></LogoMc>
                                                                    }
                                                                    {
                                                                        (isIbkCard === 'valid') &&
                                                                        <i className="ibk-card-ico">
                                                                            <IconIbk></IconIbk>
                                                                        </i>
                                                                    }
                                                                </div>
                                                                {
                                                                    ((type == '3' || type == '7') && isIbkCard === 'invalid') &&
                                                                    <div className="invalid-ibk-card">
                                                                        <small className="text-danger">
                                                                            Para dividir su pago en cuotas, debe usar una tarjeta de cr&eacute;dito Interbank.
                                                                        </small>
                                                                    </div>
                                                                }
                                                                <div className={'form-group ' + (errors.placeHolder ? ' invalid' : '')}>
                                                                    <label>Titular de la tarjeta</label>
                                                                    <input type="text" className="form-control full-story-hidden"
                                                                        placeholder="Ingresa el nombre del titular"
                                                                        value={this.state.placeHolder}
                                                                        onChange={(e) => this.handleChangeValue('placeHolder', e)} />
                                                                </div>
                                                                {
                                                                    (type == 3 || true) &&
                                                                    <div className={'form-group ' + (errors.placeHolderDocumentNumber ? ' invalid' : '')}>
                                                                        <label>Documento de identidad</label>
                                                                        <input type="text" placeholder="Ingresa tu n&uacute;mero de documento" className="form-control" required name="placeHolderDocumentNumber" value={this.state.placeHolderDocumentNumber} onChange={(e) => this.handleChangeValue('placeHolderDocumentNumber', e)} />
                                                                    </div>
                                                                }
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className={'form-group ' + (errors.expirationDate ? ' invalid' : '')}>
                                                                            <label>Fecha de vencimiento</label>
                                                                            <div id="exp-date" className="form-control full-story-hidden payu-item"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className={"form-group" + (errors.unmaskedCvv ? ' invalid' : '')}>
                                                                            <label>
                                                                                Ingresa CVV
                                                                                <span className="cvv-help">
                                                                                    <IconHelp></IconHelp>
                                                                                    <div className="cvv-help__content">Es un código de verificación de 3 o 4 dígitos ubicado en el reverso de la tarjeta de crédito o débito.</div>
                                                                                </span>
                                                                            </label>
                                                                            <input type="text" className="form-control full-story-hidden"
                                                                                placeholder=""
                                                                                value={this.state.unmaskedCvv}
                                                                                maxLength={4}
                                                                                onChange={(e) => this.handleChangeValue('unmaskedCvv', e)}
                                                                                onBlur={(e) => this.onCvvBlur()} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </React.Fragment>
                                                }
                                                {/* Divide it */}
                                                {
                                                    (type == '3') &&
                                                    <div className="credit-card">
                                                        <div className="divide-it">
                                                            {this.renderInstallmentsList()}
                                                            {
                                                                (installmentsList && installmentsList.length > 0 && installments > 1) &&
                                                                <div className="card-alert purple">TEA preferencial de {installments > 3 ? '40' : '0'}%</div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (type == '7') &&
                                                    <div className="credit-card">
                                                        <div className="divide-it">
                                                            {this.renderFreeInstallmentsList()}
                                                            <div className="card-alert purple">TEA preferencial de 0%</div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (type == 5) &&
                                                    <React.Fragment>
                                                        <h4 className="section-title">Puedes pagar en</h4>
                                                        <div className="payment__info">
                                                            <b>Banca Móvil / Internet -</b> Paga en BBVA, BCP,
                                                            Interbank, Scotiabank, BanBif, Caja Arequipa y Banco
                                                            Pichincha, a través de la opción pago de servicios.
                                                            <div className="logos mt-3 mb-3">
                                                                <img src="/pe/payment-agents/bbva.png" alt="" />
                                                                <img src="/pe/payment-agents/bcp.png" alt="" />
                                                                <img src="/pe/payment-agents/ibk.png" alt="" />
                                                                <img src="/pe/payment-agents/scotia.png" alt="" />
                                                                <img src="/pe/payment-agents/banbif.png" alt="" />
                                                                <img src="/pe/payment-agents/caja-arequipa.png" alt="" />
                                                                <img src="/pe/payment-agents/pichincha.png" alt="" />
                                                            </div>
                                                            <b>Agentes y Bodegas -</b> Deposita en BBVA, BCP,
                                                            Interbank, Scotiabank, BanBif, Western Union, Tambo+,
                                                            Kasnet, Ya Ganaste, Red Digital, Agente Niubiz, MoneyGram,
                                                            Caja Arequipa, Disashop, Cellpower.
                                                            <div className="logos mt-3">
                                                                <img src="/pe/payment-agents/bbva.png" alt="" />
                                                                <img src="/pe/payment-agents/bcp.png" alt="" />
                                                                <img src="/pe/payment-agents/ibk.png" alt="" />
                                                                <img src="/pe/payment-agents/scotia.png" alt="" />
                                                                <img src="/pe/payment-agents/banbif.png" alt="" />
                                                                <img src="/pe/payment-agents/wu.png" alt="" />
                                                                <img src="/pe/payment-agents/tambo.png" alt="" />
                                                                <img src="/pe/payment-agents/kasnet.png" alt="" />
                                                                <img src="/pe/payment-agents/ya-ganaste.png" alt="" />
                                                                <img src="/pe/payment-agents/red-digital.png" alt="" />
                                                                <img src="/pe/payment-agents/niubiz.png" alt="" />
                                                                <img src="/pe/payment-agents/moneygram.png" alt="" />
                                                                <img src="/pe/payment-agents/caja-arequipa.png" alt="" />
                                                                <img src="/pe/payment-agents/disashop.png" alt="" />
                                                                <img src="/pe/payment-agents/cellpower.png" alt="" />
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                <div className="credit-card">
                                                    {
                                                        (checkout.total > 0) &&
                                                        <React.Fragment>
                                                            {this.renderInvoiceSelector()}
                                                        </React.Fragment>
                                                    }
                                                    {
                                                        (orderType === 'factura') &&
                                                        <React.Fragment>
                                                            <div className={"form-group" + (errors.entityIdentificationNumber ? ' invalid' : '')}>
                                                                <label>RUC</label>
                                                                <input type="text" className="form-control" required name="entityIdentificationNumber" id="entityIdentificationNumber" value={this.state.entityIdentificationNumber} onChange={(e) => this.handleChangeValue('entityIdentificationNumber', e)} />
                                                            </div>
                                                            <div className={"form-group" + (errors.entityName ? ' invalid' : '')}>
                                                                <label>Raz&oacute;n Social</label>
                                                                <input type="text" className="form-control" required name="entityName" id="entityName" value={this.state.entityName} onChange={(e) => this.handleChangeValue('entityName', e)} />
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                    {
                                                        (checkout.total > 0) &&
                                                        <React.Fragment>
                                                            <h4 className="section-title">Dirección {this.state.orderType === 'boleta' ? 'para la boleta' : 'de facturación'}</h4>
                                                            <div className="billing">
                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio" id="billingAddressSame" name="billingAddress" value="same" className="custom-control-input" checked={billingAddress === 'same'} onChange={(e) => { this.handleChangeBillingAddress(e, 'same') }} />
                                                                    <label className="custom-control-label" htmlFor="billingAddressSame">Igual que la dirección de envío.</label>
                                                                </div>
                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio" id="billingAddressDifferent" name="billingAddress" value="diff" className="custom-control-input" checked={billingAddress === 'diff'} onChange={(e) => { this.handleChangeBillingAddress(e, 'diff') }} />
                                                                    <label className="custom-control-label" htmlFor="billingAddressDifferent">Usar una dirección diferente.</label>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                    {
                                                        (billingAddress === 'diff') &&
                                                        <React.Fragment>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className={'form-group ' + (errors.department ? 'invalid' : '')}>
                                                                        <label>Departamento</label>
                                                                        <select className="form-control" required name="department" value={this.state.department} onChange={(e) => { this.handleChangeValue('department', e); this.loadProvinces(e) }}>
                                                                            <option value="0" disabled>Seleccionar</option>
                                                                            {
                                                                                ubigeos.departments.map(d => {
                                                                                    return (
                                                                                        <option key={'d-' + d.id} value={d.id}>{d.name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className={'form-group ' + (errors.province ? 'invalid' : '')}>
                                                                        <label>Provincia</label>
                                                                        <select className="form-control" required name="province" value={this.state.province} onChange={(e) => { this.handleChangeValue('province', e); this.loadDistricts(e) }}>
                                                                            <option value="0">Seleccionar</option>
                                                                            {
                                                                                ubigeos.provinces.map(d => {
                                                                                    return (
                                                                                        <option key={'p-' + d.id} value={d.id}>{d.name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className={'form-group ' + (errors.district ? 'invalid' : '')}>
                                                                        <label>Distrito</label>
                                                                        <select className="form-control" required name="district" value={this.state.district} onChange={(e) => { this.handleChangeValue('district', e); this.setPostalCode(e) }}>
                                                                            <option value="0">Seleccionar</option>
                                                                            {
                                                                                ubigeos.districts.map(d => {
                                                                                    return (
                                                                                        <option key={'ds-' + d.id} value={d.id}>{d.name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className={'form-group ' + (errors.addressLine ? 'invalid' : '')}>
                                                                        <label>Direcci&oacute;n</label>
                                                                        <input type="text" className="form-control"
                                                                            placeholder="Calle/Oficina/Jiron"
                                                                            value={this.state.addressLine}
                                                                            onChange={(e) => this.handleChangeValue('addressLine', e)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className={'form-group ' + (errors.addressNumber ? 'invalid' : '')}>
                                                                        <label>Num. de casa/Dpto/Oficina</label>
                                                                        <input type="text" className="form-control"
                                                                            placeholder="Num. de casa"
                                                                            value={this.state.addressNumber}
                                                                            onChange={(e) => this.handleChangeValue('addressNumber', e)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className={'form-group'}>
                                                                        <label>Referencia para llegar</label>
                                                                        <input type="text" className="form-control"
                                                                            placeholder="Referencia"
                                                                            value={this.state.reference}
                                                                            onChange={(e) => this.handleChangeValue('reference', e)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                    <h4 className="section-title">Términos y condiciones</h4>
                                                    <div className="policies">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="privacyCheck" name="privacy" checked={policies.privacy} onChange={(e) => { this.onChangePolicyCheck(e) }} />
                                                            <label className="custom-control-label" htmlFor="privacyCheck">
                                                                Autorizo a Shopstar.pe el uso de mis datos personales para finalidades opcionales previstas en la <a href="https://shopstar.pe/Institucional/politica-de-privacidad" target="_blank">Pol&iacute;tica de Privacidad.</a>
                                                            </label>
                                                        </div>
                                                        <div className={"custom-control custom-checkbox" + (errors.terms ? ' invalid' : '')}>
                                                            <input type="checkbox" className="custom-control-input" id="termsCheck" name="terms" checked={policies.terms} onChange={(e) => { this.onChangePolicyCheck(e) }} />
                                                            <label className="custom-control-label" htmlFor="termsCheck">
                                                                Acepto los <a href="https://www.shopstar.pe/Institucional/terminos-y-condiciones" target="_blank">t&eacute;rminos y condiciones.</a>
                                                            </label>
                                                        </div>
                                                        <div className={"custom-control custom-checkbox mb-4" + (errors.personalData ? ' invalid' : '')}>
                                                            <input type="checkbox" className="custom-control-input" id="personalDataCheck" name="personalData" checked={policies.personalData} onChange={(e) => { this.onChangePolicyCheck(e) }} />
                                                            <label className="custom-control-label" htmlFor="personalDataCheck">
                                                                Acepto los <a href="https://www.shopstar.pe/terminos-y-condiciones-de-pago" target="_blank">t&eacute;rminos y condiciones de pago y canje.</a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                                <div className="card-footer mt-5">
                                    <h4>
                                        {
                                            (type != 2 && ((type != 3 && type != 7) || installments <= 1)) &&
                                            <React.Fragment>
                                                {
                                                    (type == 4) &&
                                                    <React.Fragment>
                                                        {
                                                            (isIbkCard === 'valid') &&
                                                            // <React.Fragment>Total a pagar {this.formatMoney(totalIbk)}</React.Fragment>
                                                            <React.Fragment>Total a pagar {this.formatMoney(this.calculateRestAmount())}</React.Fragment>
                                                        }
                                                        {
                                                            (isIbkCard === '' || isIbkCard === 'invalid') &&
                                                            <React.Fragment>Total a pagar {this.formatMoney(this.calculateRestAmount())}</React.Fragment>
                                                        }
                                                    </React.Fragment>
                                                }
                                                {
                                                    (type != 4) &&
                                                    <React.Fragment>
                                                        Total a pagar {this.formatMoney(this.calculateRestAmount())}
                                                    </React.Fragment>
                                                }
                                                {
                                                    (type == 2) &&
                                                    <span>

                                                    </span>
                                                }
                                            </React.Fragment>
                                        }
                                        {
                                            ((type == 3 || type == 7) && installments > 1) &&
                                            <React.Fragment>
                                                Total a pagar ahora {this.formatMoney(installmentAmount)}&nbsp;&nbsp;(Cuota 1 de {installments})
                                            </React.Fragment>
                                        }
                                        {
                                            (type == 2 && !miles.loading) &&
                                            <React.Fragment>
                                                Total a pagar {this.formatMoney(this.calculateRestAmount())}
                                                &nbsp;y {this.formatNumber(miles.selected)} Millas {miles.loading}
                                            </React.Fragment>
                                        }
                                    </h4>
                                    {
                                        (cardProvider === 'Izipay' && (type != 3 && type != 7)) &&
                                        <React.Fragment>
                                            <div className="card shp-msg-plin mb-3">
                                                <div className="card-body p-4">
                                                    Dar click en <b style={{ color: '#01A0E3' }}>"Ir a pagar"</b> para ingresar los datos de su tarjeta. ¡gracias!
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    <button type="button" className="btn btn-main" onClick={this.handleSubmitPayment}>
                                        {
                                            (type != 5 && type != 6) &&
                                            <React.Fragment>
                                                {
                                                    (cardProvider === 'Izipay') &&
                                                    <React.Fragment>
                                                        <span>Ir a Pagar</span>
                                                    </React.Fragment>
                                                }
                                                {
                                                    (cardProvider !== 'Izipay') &&
                                                    <React.Fragment>
                                                        <span>Pagar</span>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }
                                        {
                                            (type == 5) &&
                                            <span>Generar c&oacute;digo de pago</span>
                                        }
                                        {
                                            (type == 6) &&
                                            <span>Generar QR de pago</span>
                                        }
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>

                <div className="modal fade payment-modal" id="milesModal" role="dialog" aria-labelledby="milesModalLabel" data-backdrop="static" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <IconUser></IconUser>
                                <h4>Ingresa a tu cuenta de Interbank Benefit para usar tus Millas en Shopstar</h4>
                                <p className="text-center">Al darle clic a “Continuar” te llevaremos por un momento al sitio seguro de Interbank.</p>
                                <button className="btn btn-main" type="button" onClick={(e) => { redirectInterbankLogin() }}>Continuar</button>
                                <button className="btn btn-link" type="button" onClick={(e) => { this.closeValidateMilesModal() }}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade benefit-login-modal payment-modal" id="benefitLoginModal" role="dialog" aria-labelledby="benefitLoginModalLabel" data-backdrop="static" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a role="button" onClick={(e) => { this.toggleModal("#benefitLoginModal", "hide") }}></a>
                                {this.showMilesLoginForm()}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade payment-modal loading-modal" id="loadingModal" role="dialog" aria-labelledby="milesModalLabel" data-backdrop="static" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                {/* <i className="fas fa-spinner fa-pulse fa-2x mb-3"></i> */}
                                <div className="header">
                                    <a href="https://shopstar.pe">
                                        <img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
                                    </a>
                                </div>
                                <img src="/loader.gif" alt="" />
                                {
                                    (type == 7 || type == 3) &&
                                    <h4>Estamos procesando tu pago</h4>
                                }
                                {
                                    (type == 5) &&
                                    <h4>Estamos generando tu c&oacute;digo de pago</h4>
                                }
                                {
                                    (type == 6) &&
                                    <h4>Estamos generando tu QR de pago</h4>
                                }
                                <p className="text-center">
                                    Porfavor, no cierres esta página.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade payment-modal loading-modal" id="changePaymentMethodLoaderModal" role="dialog" aria-labelledby="milesModalLabel" data-backdrop="static" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <i className="fas fa-spinner fa-pulse fa-2x mb-3"></i>
                                {/* <div className="header">
                                    <a href="https://shopstar.pe">
                                        <img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
                                    </a>
                                </div> */}
                                {/* <img src="/loader.gif" alt="" /> */}
                                {/* <h4>Estamos procesando tu pago</h4> */}
                                {/* <p className="text-center mb-0">
                                    {
                                        (type == 5) &&
                                        <span>Cargando lugares de pago...</span>
                                    }
                                    {
                                        (type == 6) &&
                                        <span>Actualizando m&eacute;todo de pago</span>
                                    }
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade payment-modal  loading-modal" id="errorModal" role="dialog" aria-labelledby="milesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a role="button" className="close" onClick={(e) => { this.toggleModal("#errorModal", "hide") }}></a>
                                {/* <i className="far fa-times-circle fa-2x mb-3"></i> */}
                                <div className="header">
                                    <a href="https://shopstar.pe">
                                        <img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
                                    </a>
                                </div>
                                <img src="/alert-3d.png" alt="" width="150px" />
                                <h4>{error}</h4>
                                <div className="payment-alert">
                                    No se ha realizado ning&uacute;n cargo a tu tarjeta {maskedCardNumber}
                                </div>
                                <p className="text-center">
                                    Por favor, int&eacute;ntalo con otra forma de pago o comun&iacute;cate con tu banco.
                                </p>
                                <button className="btn btn-main" type="button" onClick={(e) => { this.toggleModal("#errorModal", "hide") }}>Volver a intentarlo</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade payment-modal  loading-modal" id="basicErrorModal" role="dialog" aria-labelledby="milesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a role="button" className="close" onClick={(e) => { this.toggleModal("#basicErrorModal", "hide") }}></a>
                                <div className="header">
                                    <a href="https://shopstar.pe">
                                        <img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
                                    </a>
                                </div>
                                <img src="/alert-3d.png" alt="" width="150px" />
                                <h4>{error}</h4>
                                <button className="btn btn-main" type="button" onClick={(e) => { this.toggleModal("#basicErrorModal", "hide") }}>Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade payment-modal" id="benefitCardErrorModal" role="dialog" aria-labelledby="milesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <i className="far fa-times-circle fa-2x mb-3"></i>
                                <h4>¡Ups!</h4>
                                <p className="text-center">
                                    Para poder usar tus millas, debes ingresar una tarjeta de cr&eacute;dito o d&eacute;bito Interbank.
                                </p>
                                <button className="btn btn-link" type="button" onClick={(e) => { this.toggleModal("#benefitCardErrorModal", "hide") }}>Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade payment-modal" id="promoPlinErrorModal" role="dialog" aria-labelledby="milesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <i className="far fa-frown fa-2x mb-3"></i>
                                <h4>¡Ups!</h4>
                                <p className="text-center">
                                    Hola, tu compra no puede ser procesada al haber superado el limite de 5 unidades de la promoción con Plin. Gracias por tu preferencia.
                                </p>
                                <button className="btn btn-link" type="button" onClick={(e) => { this.toggleModal("#promoPlinErrorModal", "hide") }}>Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade payment-modal payment-modal-izi" id="errorModalIzipayData" role="dialog" aria-labelledby="errorModalIzipayData" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <div className="modal-title">
                                    <h3 className="modal-title-izipay">¡Int&eacute;ntalo de nuevo!</h3>
                                    <button type="button" className="close rounded-circle" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">x</span>
                                    </button>
                                </div>
                            </div> */}
                            <div className="modal-body">
                                <div className="pb-5 pt-5">
                                    <img src="/izipay_data_error.png" alt="" className="pb-5 pt-5" />
                                </div>
                                <h3>¡Int&eacute;ntalo de nuevo!</h3>
                                <div>
                                    <p className="text-center">
                                        ¡Oops! Parece que los datos de tu tarjeta son <br />errados.
                                        Por favor, verifica la información e <br />inténtalo de nuevo.
                                        ¡Gracias por tu paciencia!
                                    </p>
                                </div>

                                {/* <div className="d-block w-100 text-center">
                                    <button className="btn" type="button" onClick={(e) => { this.toggleModal("#errorModalIzipay", "hide") }}>De acuerdo</button>
                                </div> */}
                                <div className="modal-body-footer">

                                </div>
                            </div>
                            <div className="modal-footer">
                                <p className="text-center w-100">
                                    <button type="button" className="btn btn-main" onClick={(e) => { this.refresh() }}>Intentar nuevamente</button>
                                    {/* Redireccionado al sitio de Shopstar en <b>5 segundos</b> <i className="far fa-clock"></i>
                                    <br />
                                    <b className="w-100">POWER BY  <img src="https://developers.izipay.pe/logo/light.svg" width="50" /></b> */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade payment-modal payment-modal-izi" id="errorModalPagoPushIzipay" role="dialog" aria-labelledby="errorModalIzipayData" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <ErrorModal
                                    title={`¡Inténtalo de nuevo!`}
                                    description={`¡Oops! Parece que los datos de tu tarjeta son errados. Por favor, verifica la información e inténtalo de nuevo. ¡Gracias por tu paciencia!`}
                                    image={`img/error.webp`}
                                    buttonLabel={`Volver a intentarlo`}
                                    onButtonClick={() => { console.info(`retry!`) }}
                                    code={"error_1"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade payment-modal payment-modal-izi" id="okModalPagoPushIzipay" role="dialog" aria-labelledby="errorModalIzipayData" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <SuccessModal
                                // title={`¡Inténtalo de nuevo!`} 
                                // description={`¡Oops! Parece que los datos de tu tarjeta son errados. Por favor, verifica la información e inténtalo de nuevo. ¡Gracias por tu paciencia!`}
                                // image={`img/error.webp`}
                                // buttonLabel={`Volver a intentarlo`} 
                                // onButtonClick={() => { console.info(`retry!`)}}
                                // code={"error_1"}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade payment-modal payment-modal-izi" id="errorModalIzipaySaldo" role="dialog" aria-labelledby="errorModalIzipaySaldo" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="pt-5">
                                    <img src="/izipay_fondo_insuficiente.png" alt="" className="pb-5 pt-5" width="75px" />
                                </div>
                                <h3>¡Int&eacute;ntalo de nuevo!</h3>
                                <div>
                                    <p className="text-center">
                                        !Oops! tu saldo es insuficiente.
                                        Por favor, <br />
                                        verifica tu cuenta y vuelve a intentarlo.
                                        ¡Gracias <br />por tu paciencia!
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <p className="text-center w-100">
                                    <button type="button" className="btn btn-main" onClick={(e) => { this.refresh() }}>Intentar nuevamente</button>
                                    {/* Redireccionado al sitio de Shopstar en <b>5 segundos</b> <i className="far fa-clock"></i>
                                    <br />
                                    <b className="w-100">POWER BY  <img src="https://developers.izipay.pe/logo/light.svg" width="50" /></b> */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade payment-modal payment-modal-izi" id="errorModalIzipayMissingFields" role="dialog" aria-labelledby="errorModalIzipayMissingFields" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="pt-5">
                                    <img src="/izipay-missing-fileds.png" alt="" className="pb-3 pt-3" width="200px" />
                                </div>
                                <h3>¡Int&eacute;ntalo de nuevo!</h3>
                                <div>
                                    <p className="text-center">
                                        ¡Oops!, te faltaron llenar algunos datos.
                                        <br />
                                        Por favor, completa los datos en el formulario.
                                        <br />
                                        ¡Gracias!
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <p className="text-center w-100">
                                    <button type="button" className="btn btn-main" onClick={(e) => { this.refresh() }}>Intentar nuevamente</button>
                                    {/* Redireccionado al sitio de Shopstar en <b>5 segundos</b> <i className="far fa-clock"></i>
                                    <br />
                                    <b className="w-100">POWER BY  <img src="https://developers.izipay.pe/logo/light.svg" width="50" /></b> */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade payment-modal payment-modal-izi" id="errorModalIzipayTimeout" role="dialog" aria-labelledby="errorModalIzipayTimeout" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="pt-5">
                                    <img src="/izipay_tiempo_caducado.png" alt="" className="pb-3 pt-3" width="180px" />
                                </div>
                                <h3>¡Int&eacute;ntalo de nuevo!</h3>
                                <div>
                                    <p className="text-center">
                                        ¡Oops!, el formulario se cerr&oacute; por haber alcanzado el tiempo l&iacute;mite de espera.
                                        <br />
                                        Por favor, int&eacute;ntalo nuevamente.
                                        <br />
                                        ¡Gracias!
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <p className="text-center w-100">
                                    <button type="button" className="btn btn-main" onClick={(e) => { this.refresh() }}>Intentar nuevamente</button>
                                    {/* Redireccionado al sitio de Shopstar en <b>5 segundos</b> <i className="far fa-clock"></i>
                                    <br />
                                    <b className="w-100">POWER BY  <img src="https://developers.izipay.pe/logo/light.svg" width="50" /></b> */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade payment-modal payment-modal-izi" id="errorModalIzipayBoton" role="dialog" aria-labelledby="errorModalIzipayBoton" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="pt-5 pb-5">
                                    <img src="/izipay_boton_disabled.png" alt="" className="pb-5 pt-5" width="75px" />
                                </div>
                                <h3>¡Int&eacute;ntalo de nuevo!</h3>
                                <div>
                                    <p className="text-center">
                                        !Oops! Parece que las compras en l&iacute;nea est&aacute;n <br />
                                        temporalmente desactivadas.
                                        Int&eacute;ntalo de <br />nuevo m&aacute;s tarde.
                                        ¡Gracias por tu paciencia!
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <p className="text-center w-100">
                                    <button type="button" className="btn btn-main" onClick={(e) => { this.refresh() }}>Intentar nuevamente</button>
                                    {/* Redireccionado al sitio de Shopstar en <b>5 segundos</b> <i className="far fa-clock"></i>
                                    <br />
                                    <b className="w-100">POWER BY  <img src="https://developers.izipay.pe/logo/light.svg" width="50" /></b> */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade payment-modal payment-modal-izi" id="errorModalIzipayDenegada" role="dialog" aria-labelledby="errorModalIzipayDenegada" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="pt-5">
                                    <img src="/izipay_transaccion_denegada2.png" alt="" className="pb-5 pt-5" />
                                </div>
                                <h3>¡Int&eacute;ntalo de nuevo!</h3>
                                <div>
                                    <p className="text-center">
                                        !Oops!, transacción denegada. <br />Por favor, contacta a tu banco e inténtalo de nuevo. <br />¡Gracias!
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <p className="text-center w-100">
                                    <button type="button" className="btn btn-main" onClick={(e) => { this.refresh() }}>Intentar nuevamente</button>
                                    {/* Redireccionado al sitio de Shopstar en <b>5 segundos</b> <i className="far fa-clock"></i>
                                    <br />
                                    <b className="w-100">POWER BY  <img src="https://developers.izipay.pe/logo/light.svg" width="50" /></b> */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade payment-modal payment-modal-izi" id="errorModalIzipayTiempo" role="dialog" aria-labelledby="errorModalIzipayTiempo" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="mt-5 pt-5">
                                    <img src="/izipay_tiempo_caducado2.png" alt="" className="pb-4 pt-2" />
                                </div>
                                <h3>¡Int&eacute;ntalo de nuevo!</h3>
                                <div>
                                    <p className="text-center">
                                        "Lo sentimos, el tiempo de espera ha caducado. <br />Por favor, realiza la acción nuevamente. ¡Gracias!"
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <p className="text-center w-100">
                                    <button type="button" className="btn btn-main" onClick={(e) => { this.refresh() }}>Intentar nuevamente</button>
                                    {/* Redireccionado al sitio de Shopstar en <b>5 segundos</b> <i className="far fa-clock"></i>
                                    <br />
                                    <strong className="w-100">POWER BY  <img src="https://developers.izipay.pe/logo/light.svg" width="50" /></strong> */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade payment-modal payment-modal-izi payment-ok-izi" id="okModalIzipay" role="dialog" aria-labelledby="okModalIzipay" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="text-center">
                                    <h3>¡Pago exitoso!</h3>
                                </div>
                                <div>
                                    <p className="text-center desc-ok">
                                        Tu transacci&oacute;n ha sido exitosa.<br />
                                        ¡Gracias por confiar en nosotros!
                                    </p>
                                    <p className="text-center">
                                        <b>Monto pagado: &nbsp;</b> {this.formatMoney(this.calculateRestAmount())}
                                    </p>
                                </div>
                                <div className="modal-body-footer">
                                    <div className="d-block">
                                        <img src="/izipay_body_footer_ok.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade payment-modal payment-modal-izi" id="loadingModalIziPay" role="dialog" aria-labelledby="milesModalLabel" data-backdrop="static" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                {/* <div className="header">
                                    <a href="https://shopstar.pe">
                                        <img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
                                    </a>
                                </div> */}
                                <img src="/loader.gif" alt="" width="80%" />
                                <h4 className="modal-title-h4">Estamos cargando el <br />Formulario de pago</h4>

                                <p className="text-center">
                                    Por favor, no cierres esta página.
                                </p>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade payment-modal payment-modal-izi" id="loadingModalIziPushPay" role="dialog" aria-labelledby="milesModalLabel" data-backdrop="static" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                {/* <div className="header">
                                    <a href="https://shopstar.pe">
                                        <img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
                                    </a>
                                </div> */}
                                <img src="/loader.gif" alt="" width="80%" />
                                <h4 className="modal-title-h4">Estamos cargando el <br />Formulario de pago</h4>

                                <p className="text-center">
                                    Por favor, no cierres esta página.
                                </p>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}

export default AddressForm;
