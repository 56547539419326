const keys = {
    kushkiId: process.env.REACT_APP_KUSHKI_ID,
    kushkiIdInstallments: process.env.REACT_APP_KUSHKI_ID_INSTALLMENTS,
    interbankLoginPage: process.env.REACT_APP_INTERBANK_LOGIN_PAGE,
    interbankLoginPageV2: process.env.REACT_APP_INTERBANK_LOGIN_PAGE_V2,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    redirectUriV2: process.env.REACT_APP_REDIRECT_URI_V2,
    isTesting: process.env.REACT_APP_KUSHKI_TEST === "true" ? true : false,
    siteUrl: process.env.REACT_APP_SITE_URL,
    payuEnv: process.env.REACT_APP_PAYU_ENV,
    payuApiKey: process.env.REACT_APP_PAYU,
    payuInstallmentsApiKey: process.env.REACT_APP_PAYU_INSTALLMENTS,
    muscleAuthUrl: process.env.REACT_APP_MUSCLE_AUTH_URL,
    muscleCartCashAndPointsUrl: process.env.REACT_APP_MUSCLE_CART_CASH_AND_POINTS_URL,
    musclePurchaseCompletedUrl: process.env.REACT_APP_MUSCLE_PURCHASE_COMPLETE_URL,
    muscleClientId: process.env.REACT_APP_MUSCLE_CLIENT_ID,
    muscleClientSecret: process.env.REACT_APP_MUSCLE_CLIENT_SECRET,
    iziMerchantCode: process.env.REACT_APP_IZI_MERCHANT_CODE,
    iziPublicKey: process.env.REACT_APP_IZI_PUBLIC_KEY,
    iziRsa: process.env.REACT_APP_IZI_RSA,
    iziCardMerchantCode: process.env.REACT_APP_IZI_CARD_MERCHANT_CODE,
    iziCardPublicKey: process.env.REACT_APP_IZI_CARD_PUBLIC_KEY,
    iziIbkCardMerchantCode: process.env.REACT_APP_IZI_IBK_CARD_MERCHANT_CODE,
    iziIbkCardPublicKey: process.env.REACT_APP_IZI_IBK_CARD_PUBLIC_KEY,
    iziIbkDebitCardMerchantCode: process.env.REACT_APP_IZI_IBK_DEBIT_CARD_MERCHANT_CODE,
    iziIbkDebitCardPublicKey: process.env.REACT_APP_IZI_IBK_DEBIT_CARD_PUBLIC_KEY,
    iziPushPaymentMerchantCode: process.env.REACT_APP_IZI_PUSH_PAYMENT_MERCHANT_CODE,
    iziPushPaymentPublicKey: process.env.REACT_APP_IZI_PUSH_PAYMENT_PUBLIC_KEY,
}

export const KEYS = keys;
