import { Util } from '@microsoft/applicationinsights-web';
import axios from 'axios';
import { api } from '../api/api';

const httpHeaders = {
    headers: {
        'content-type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }
};

export const getApiUrl = () => {
    return api;
}

export const getCheckoutPromotion = async (promotionId) => {
    var result = null;
    try {
        result = await axios.get(`${api}backend/checkout/info-link/${promotionId}`);
    } catch (e) {
        result = e.response
    }
    return result;
}

export const getIpInfo = async () => {
    var result = null;
    try {
        result = await axios.get(`https://www.cloudflare.com/cdn-cgi/trace`);
    } catch (e) {
        result = e.response
    }
    return result;
}

export const generateCheckoutLink = async (model) => {
    var result = null;
    try {
        result = await axios.post(`${api}backend/checkout/generate-link`, model);
    } catch (e) {
        result = e.response
    }
    return result;
}

export const startCheckout = async (id, email, identificationNumber, linkId) => {
    var result = null;
    try {
        result = await axios.post(`${api}checkout/start?id=${id}&email=${email}&calculateIBK=true&identificationNumber=${identificationNumber}&linkId=${linkId}`);
        localStorage.setItem("__checkoutItems__",JSON.stringify(result.data.items))
    } catch (e) {
        result = e.response
    }
    return result;
}

export const updatePromos = async (model) => {
    var result = null;
    try {
        result = await axios.post(`${api}checkout/update-promos`, model);
    } catch (e) {
        result = e.response
    }
    return result;
}

export const validateEmail = async (model) => {
    var result = null;
    try {
        result = await axios.post(`${api}checkout`, model);
    } catch (e) {
        result = e.response
    }
    return result;
}

export const getCheckout = async (id) => {
    return await axios.get(`${api}checkout/${id}`);
}

export const getInstallments = async (id) => {
    return await axios.get(`${api}checkout/${id}/installments`);
}

export const getInstallmentsV2 = async (id, model) => {
    return await axios.post(`${api}checkout/${id}/installments`, model);
}

export const getFreeInstallmentsPriceType = async () => {
    return await axios.get(`${api}free-installmentsprice-type`);
}

export const getDiscount = async (id, model) => {
    return await axios.put(`${api}checkout/${id}/payment`, model);
}

export const getMiles = async (model) => {
    var result = null;
    try {
        result = await axios.post(`${api}miles/v2/getMiles`, model);
    } catch (e) {
        result = e.response;
    }

    return result;
}

export const getMilesToken = async (model) => {
    var result = null;
    try {
        result = await axios.post(`${api}miles/getToken`, model);
    } catch (e) {
        result = e.response;
    }

    return result;
}

export const getOauthToken = async (model) => {
    var result = null;
    try {
        result = await axios.post(`${api}ibk/v2/oauth/token`, model);
    } catch (e) {
        result = e.response;
    }

    return result;
}

export const deleteCartItem = async (id, productId, model) => {
    var result = null;
    try {
        result = await axios.post(`${api}checkout/${id}/items/${productId}/delete`, model);
    } catch (e) {
        result = e.response;
    }

    return result;
}

export const updateUser = async (id, user) => {
    return await axios.put(`${api}checkout/${id}/profile`, user);
}

export const saveAddress = async (id, address) => {
    return await axios.put(`${api}checkout/${id}/shipping`, address);
}

export const saveShippingInfo = async (id, info) => {
    return await axios.put(`${api}checkout/${id}/shipping/selected`, info);
}

export const saveOrder = async (model) => {
    let result = null;

    try {
        result = await axios.post(`${api}transactions/v4/orders`, model);
    } catch (e) {
        result = e.response;
    }

    return result;
}

export const saveOrderV5 = async (model) => {
    let result = null;

    try {
        result = await axios.post(`${api}transactions/v10/orders`, model);
    } catch (e) {
        result = e.response;
    }

    return result;
}

export const saveOrderV10 = async (model) => {
    let result = null;

    try {
        result = await axios.post(`${api}transactions/v10/orders`, model);
    } catch (e) {
        result = e.response;
    }

    return result;
}

export const invalidateOrder = async (model) => {
    return await axios.post(`${api}transactions/invalidate`, model);
}

export const confirmOrder = async (model) => {
    return await axios.post(`${api}transactions/confirm`, model);
}

export const setOrderNumber = async (model) => {
    return await axios.post(`${api}transactions/order-number`, model);
}

export const authorizePayment = async (model) => {
    var url = `${api}transactions/v2/orders/authorize-capture`;
    var result = null;

    if (model.gateway === 'payu') {
        url = `${api}transactions/v3/orders/authorize`;
    }

    try {
        result = await axios.post(url, model, httpHeaders);
    } catch (e) {
        result = e.response;
    }

    return result;
}

export const sendEmblueData = async (model) => {
    let url = `https://track.embluemail.com/contacts/event`;
    let headers = {
        'Authorization': 'Basic YTNiZjcwZGQyYTU5NDI0NzkxYTllZDEzM2ZlZjY5MjE=',
        'Content-Type': 'application/json'
    }

    return await axios.post(url, model, { headers: headers });
}

export const sendHubspotData = async (email, model) => {
    let url = `https://api.hubapi.com/contacts/v1/contact/createOrUpdate/email/${email}/?hapikey=c78c1d8f-cd47-4311-af88-94bfea01a636`;

    return await axios.post(url, model);
}

export const getProductInfo = async (id) => {
    let url = `${api}checkout/products-search?productId=${id}`;

    return await axios.post(url, {});
}

export const getProductSimulation = async (model) => {
    let url = `${api}checkout/simulate`;

    return await axios.post(url, model);
}

export const getIziToken = async (model) => {
    let url = `${api}izi/token`;

    return await axios.post(url, model);
}

export const getIziCardToken = async (model, ibkCardType) => {
    let url = `${api}izi/token/card`;

    if (ibkCardType === 'debit') {
        url = `${api}izi/token/ibk-debit-card`;
    } else if (ibkCardType === 'credit') {
        url = `${api}izi/token/ibk-card`;
    }

    return await axios.post(url, model);
}

export const getIziPushPaymentToken = async (model, ibkCardType) => {
    let url = `${api}izi/token/push-payment`;

    return await axios.post(url, model);
}

export const getProductSimulationV2 = async (model) => {
    let url = `${api}checkout/simulate-list`;

    return await axios.post(url, model);
}
export const getProductSimulationPlin = async (model) => {
    let url = `${api}checkout/simulate-list/plin`;

    return await axios.post(url, model);
}

export const getOrderDetail = async (checkoutId, orderNumber) => {
    let url = `${api}transactions/orders/v2/${checkoutId}/${orderNumber}`;

    return await axios.get(url);
}

export const authorizeIzipayTransaction = async (model) => {
    let url = `${api}transactions/authorize/izipay`;

    return await axios.post(url, model);
}

export const captureConfirmIzipayTransaction = async (model) => {
    let url = `${api}transactions/confirm/push-payment`;

    return await axios.post(url, model);
}

export const failIzipayTransaction = async (model) => {
    let url = `${api}transactions/fail/izipay`;

    return await axios.post(url, model);
}