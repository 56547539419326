import React from "react";
import "./CheckoutSuccess.scss";
import * as moment from "moment";
import 'moment/locale/es';
import {
	getIziToken,
	confirmOrder,
	invalidateOrder,
	setOrderNumber,
	getApiUrl
} from './../../services/Service';
import { KEYS } from './../../constants/index';
import * as $ from 'jquery';
import CheckoutTimerQR from "./CheckoutTimerQR";
import { ReactComponent as IconContact } from '../../assets/Contacto.svg'
import { ReactComponent as IconMail } from '../../assets/Mail_qr.svg'

class CheckoutSuccessView extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		error: false,
		productsNumber: 0,
		productsTotal: 0,
		showPrices: true,
		//Izi
		iziObject: null,
		approved: false,
		card: null,
		//Error
		error: '',
	}

	componentDidMount = async () => {
		const { getOrder, match } = this.props;
		let orderNumber = match.params.id;
		let checkoutId = window.localStorage.getItem("__checkoutId__");

		window.localStorage.removeItem("__stores__");
		window.localStorage.removeItem("__shippingState__");
		window.localStorage.removeItem("__shippingItems__");
		window.localStorage.removeItem("__user__");
		window.localStorage.removeItem("__installments__");
		window.localStorage.removeItem("__address__");
		window.localStorage.removeItem("__totalIbk__");
		window.localStorage.removeItem("__checkoutId__");
		window.localStorage.removeItem("__promo__");
		window.localStorage.removeItem("__ipAddress__");
		window.localStorage.removeItem("__total__");
		window.localStorage.removeItem("__gateway__");

		moment.locale('es');

		await getOrder(checkoutId, orderNumber);

		const { order } = this.props;

		let orderInfo = order.data;
		let orders = orderInfo.orders;
		let products = [];
		let card = null;
		let orderGroup = "";
		let productsNumber = 0;
		let productsTotal = 0;
		let criteoProducts = [];
		let rrProducts = [];

		orders.forEach(order => {
			card = order.transaction;
			orderGroup = order.orderGroup;
			productsNumber += order.items.length;

			order.items.forEach(item => {
				productsTotal += item.basePrice;

				products.push({
					'id': item.skuVtexId,
					'name': item.productName,
					'price': item.price,
					'brand': '(not available)',
					'category': '(not available)',
					'variant': '(not available)',
					'quantity': item.quantity,
					'metric1': '(not available)',
					'metric2': '(not available)',
					'dimension13': order.seller.name || '(not available)',
					'dimension14': '(not available)',
					'metric3': item.price,
					'metric4': '(not available)',
					'dimension12': order.isPickUp ? 'Recojo en Tienda' : 'Entrega a Domicilio',
					'dimension15': this.showEstimatedDate(order),
					'dimension16': this.showRangeTime(order),
				})

				criteoProducts.push({
					id: item.sku,
					price: item.price,
					quantity: item.quantity
				});

				rrProducts.push({
					id: item.skuVtexId,
					price: item.price,
					qnt: item.quantity
				});
			})
		});

		this.setState({ productsNumber, productsTotal });

		this.saveDataLayerEvent({
			'event': 'purchase',
			'category': 'Ecommerce',
			'action': 'Compra',
			'label': 'Pago de clientes Interbank',
			'dimension17': orderInfo.summary.benefitsDiscount < 0 ? 'Millas' : 'Tarjeta de Credito y Debito',
			'dimension18': card ? (card.cardType || '(not available)') : '(not available)',
			'metric5': orderInfo.summary.total,
			'metric6': this.getTotalMilesDiscount(),
			'ecommerce': {
				'currencyCode': 'PEN',
				'purchase': {
					'actionField': {
						'id': orderGroup,
						'affiliation': 'Pago de clientes Interbank',
						'revenue': orderInfo.summary.total,
						'tax': '(not available)',
						'shipping': orderInfo.summary.shippingCost,
						'coupon': '(not available)'
					},
					'products': products
				}
			}
		})

		this.sendCriteoData(criteoProducts);

		(window["rrApiOnReady"] = window["rrApiOnReady"] || []).push(function () {
			try {
				window["rrApi"].setEmail(order.data.client.email);
				window["rrApi"].order({
					"transaction": orderGroup,
					"items": rrProducts
				});
			} catch (e) { }
		})

		const urlParams = new URLSearchParams(window.location.search);
		const showPrices = urlParams.get('showPrices');

		if (showPrices && showPrices !== '') {
			this.setState({
				showPrices: showPrices === 'true'
			});
		}

		if (order.data.orders[0].paymentOption === 'izipay-qr' && order.data.orders[0].orderStatusName !== 'Confirmed') {
			let _this = this;

			setTimeout(() => {
				_this.loadIziForms();
			}, 2000)
		}
	}

	isPendingStatus = () => {
		const { order } = this.props;

		if (!order)
			return false;

		let isPending = true;

		order.data.orders.forEach(order => {
			if (order.orderStatus !== 0)
				isPending = false;
		});

		return isPending;
	}

	loadIziForms = async () => {
		const { order } = this.props;
		console.log("🚀 ~ file: CheckoutSuccessView.js:165 ~ CheckoutSuccessView ~ loadIziForms= ~ order:", order)

		const currentTimeUnix = Math.floor(Date.now()) * 1000;
		// const transactionId = currentTimeUnix.toString().slice(0, 14);
		const orderNumber = currentTimeUnix.toString().slice(0, 10).toString();
		const transactionId = order.data.orders[0].transaction.transactionId;
		// const orderNumber = order.data.orders[0].orderGroup;
		const MERCHANT_CODE = KEYS.iziMerchantCode;
		const PUBLIC_KEY = KEYS.iziPublicKey;
		const ORDER_AMOUNT = order.data.summary.total.toFixed(2);
		const ORDER_CURRENCY = 'PEN';

		let isPending = this.isPendingStatus();

		if (!isPending)
			return;

		let address = `${order.data.orders[0].address.addressLine} ${order.data.orders[0].address.addressNumber}`;

		if (address.length > 40)
			address = address.substring(0, 40);

		let urlIPN = `https://ibkshopstarserviceprodgatewayv3.azurewebsites.net/transactions/${order.data.orders[0].saleOrderId}/${transactionId}/${order.data.orders[0].orderGroup}/${orderNumber}/confirm/v3`;

		const iziConfig = {
			publicKey: PUBLIC_KEY,
			// urlIPN: urlIPN,
			config: {
				transactionId: transactionId,
				action: 'pay',
				merchantCode: MERCHANT_CODE,
				order: {
					orderNumber: orderNumber,
					currency: ORDER_CURRENCY,
					amount: ORDER_AMOUNT,
					payMethod: 'QR',
					processType: 'AT',
					merchantBuyerId: 'mc1768',
					dateTimeTransaction: currentTimeUnix.toString()
				},
				card: {
					brand: '',
					pan: '',
				},
				billing: {
					firstName: order.data.client.firstName,
					lastName: order.data.client.lastName,
					email: order.data.client.email,
					phoneNumber: order.data.client.phone,
					street: address,
					city: order.data.orders[0].address.department,
					state: order.data.orders[0].address.province,
					country: 'PE',
					postalCode: order.data.orders[0].address.stateCode,
					document: order.data.client.identificationNumber,
					documentType: order.data.client.identificationType === 'dni' ? 'DNI' : 'CE',
				},
				render: {
					typeForm: 'embedded',
					container: '#izi-secure-form',
					showButtonProcessForm: true
				},
				urlRedirect: 'https://server.punto-web.com/comercio/creceivedemo.asp?p=h1',
				urlIPN: urlIPN,
				appearance: {
					logo: 'https://demo-izipay.azureedge.net/test/img/millasb.svg',
				},
			},
		};

		let paidQrOrderGroup = localStorage.getItem("__paidQrOrderGroup__");
		console.log("🚀 ~ CheckoutSuccessView ~ loadIziForms= ~ paidQrOrderGroup:", paidQrOrderGroup)

		if (!(paidQrOrderGroup && paidQrOrderGroup === order.data.orders[0].orderGroup)) {
			console.log("Setting order number");
			await setOrderNumber({ saleOrderId: order.data.orders[0].saleOrderId, transactionId: transactionId, orderNumber: orderNumber, jsonContent: JSON.stringify(iziConfig), orderGroup: order.data.orders[0].orderGroup });
		}

		console.log("🚀 ~ file: CheckoutSuccessView.js:213 ~ CheckoutSuccessView ~ loadIziForms= ~ iziConfig:", iziConfig)

		const izi = new window['Izipay']({
			publicKey: iziConfig.publicKey,
			config: iziConfig.config,
		});

		let tokenModel = {
			transactionId,
			orderNumber,
			amount: ORDER_AMOUNT
		}

		let tokenResponse = await getIziToken(tokenModel);
		let _this = this;
		const token = tokenResponse.data.access_token;

		izi &&
			izi.LoadForm({
				authorization: token,
				keyRSA: 'RSA', //KEYS.iziRsa,
				callbackResponse: async (response) => {
					console.log("Izi Response => ", response);
					if (response.code) {
						if (response.code === '022') {
							_this.setState({
								error: response.message
							});

							_this.toggleModal("#errorModal", "show");

							let invalidateModel = {
								orderGroup: order.data.orders[0].orderGroup,
								reasonType: "error_qr",
								reason: response.message
							};
							await invalidateOrder(invalidateModel);
						} else if (response.code === '05') {
							_this.setState({
								error: response.messageUser
							});

							let invalidateModel = {
								orderGroup: order.data.orders[0].orderGroup,
								reasonType: "expired_qr",
								reason: response.messageUser
							};

							_this.toggleModal("#errorModal", "show");

							await invalidateOrder(invalidateModel);
						} else if (response.code === '00') {
							_this.setState({
								approved: true,
								card: response.response.card
							});
							let model = {
								saleOrderId: order.data.orders[0].saleOrderId, 
								transactionId: transactionId, 
								orderNumber: orderNumber, 
								jsonContent: JSON.stringify(iziConfig),
								jsonResponse: JSON.stringify(response),
								orderGroup: order.data.orders[0].orderGroup
							}
							await setOrderNumber(model);
							localStorage.setItem("__paidQrOrderGroup__", order.data.orders[0].orderGroup);
						}
					}
				},
			});



		this.setState({
			iziObject: izi
		});
	}

	confirmOrder = async () => {
		const { order } = this.props;

		const transactionId = order.data.orders[0].transaction.transactionId;

		await confirmOrder({ transactionId: transactionId });
	}

	sendCriteoData = (products) => {
		const { order, match } = this.props;

		var script = `
			window.criteo_q = window.criteo_q || []; 
			var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d"; 
			window.criteo_q.push( { 
				event: "setAccount", 
				account: 55270 
			}, 
			{ 
				event: "setEmail", 
				email: "${order.data.client.email}", 
				hash_method: ""
			}, 
			{ 
				event: "setSiteType", 
				type: deviceType
			}, 
			{ 
				event: "setZipcode", 
				zipcode: "" 
			}, 
			{ 
				event: "trackTransaction", 
				id: "${match.params.id}", 
				item: ${JSON.stringify(products)}
			});`;

		eval(script);
	}

	setHttps = (url) => {
		if (url) {
			return url.replace("http:", "https:")
		}
	}

	showEstimatedDate = (order) => {
		if (order.deliveryWindowStart) {
			return moment(order.deliveryWindowStart).format('D/MM/YYYY');
		}

		let date = order.shippingEstimateDate;

		if (date && date !== "") {
			if (date.indexOf("bd") > -1) {
				date = date.replace("bd", "");
				return "En " + date + " días hábiles";
			} else if (date.indexOf("d") > -1) {
				date = date.replace("d", "");
				return "En " + date + " días";
			} else if (date.indexOf("h") > -1) {
				date = date.replace("h", "");
				return "En " + date + " horas";
			}

			return date;
		}

		return "";
	}

	saveDataLayerEvent = (event) => {
		if (window["dataLayer"]) {
			window["dataLayer"].push(event);
		}
	}

	formatCardNumber = (cardNumber) => {
		if (!cardNumber || cardNumber === '')
			return '';
		return cardNumber.substring(12);
	}

	formatMoney = (amount) => {
		return amount && amount > 0 ? new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' }).format(amount) : 'S/ 0.00';
	}

	formatNumber = (amount) => {
		return amount ? new Intl.NumberFormat('es-PE').format(amount) : '0';
	}

	showRangeTime = (order) => {
		let rangeTime = "";
		if (order.deliveryWindowStart && order.deliveryWindowStart !== '') {
			rangeTime += moment(order.deliveryWindowStart).format('h:mm a');
		}

		if (order.deliveryWindowEnd && order.deliveryWindowEnd !== '') {
			rangeTime += " " + moment(order.deliveryWindowEnd).format('h:mm a');
		}

		return rangeTime;
	}

	getTotalInstallmentsAmount = (order) => {
		let total = order.installments * order.installmentAmount;
		return this.formatMoney(total);
	}

	getTotalMilesDiscount = () => {
		const { order } = this.props;

		let total = Math.ceil(((-1 * order.data.summary.benefitsDiscount) / 0.03));

		return this.formatNumber(total);
	}

	getOrderDate = (order) => {
		let date = order.orders[0].createdOn;

		if (date && date !== '') {
			date = new Date(date);
			date.setHours(date.getHours() - 5);
			date = moment(date);
		}
		return date.format('D [de] MMMM, hh:mma');
	}

	buildSellersNames = (order) => {
		let text = "Las tiendas stores confirmaran";
		let stores = "";

		if (order.orders.length === 1) {
			stores = order.orders[0].seller.name;
			text = "Las tiendas stores confirmaran";
			text = text.replace('stores', stores);

			return text;
		}

		order.orders.forEach((el, i) => {
			if (i === 0) {
				stores += el.seller.name;
			} else if (i === (order.orders.length - 1)) {
				stores += ' y ' + el.seller.name;
			} else {
				stores += ' ,' + el.seller.name;
			}
		})

		text = text.replace('stores', stores);
		return text;
	}

	toggleModal = (modal, action) => {
		$(modal).modal(action);
	}

	toHome = () => {
		window.location.href = 'https://shopstar.pe/';
	}

	getBrandName = (code) => {
		let brandName = '';

		if (code === 'VS') {
			brandName = 'Visa';
		}

		return brandName;
	}
	renderMessageOnlyPickup = (orderData) => {
		let messageOnlyPickup = "";
		console.log('orders:', orderData);
		const foundOrder = orderData.orders.find((el) => el.isPickUp == true);

		if (!foundOrder) {
			messageOnlyPickup = "";
		} else {
			messageOnlyPickup = (
				<div className="checkout-success__body__store__help-text withoutSpacing">
					<img src="/img/info.svg" alt="" />
					El titular de la compra ({orderData.client.firstName} {orderData.client.lastName}) debe acercarse a la tienda con su documento de identidad para retirar el producto.
				</div>
			);
		}

		return <React.Fragment>{messageOnlyPickup}</React.Fragment>;
	}
	getOrderItemCancelled = (orderItem) => {
		let statusOrderItem = false;
		if (orderItem.orderStatusName === 'Rejected' || orderItem.orderStatusName === 'Invalid' || orderItem.orderStatusName === 'Cancelled') {
			statusOrderItem = true;
		}
		return statusOrderItem;
	}

	render() {
		const { order, match } = this.props;
		const { error, productsNumber, productsTotal, showPrices, approved, card } = this.state;

		let transaction = {};
		if (!order.loading && order.data) {
			transaction = order.data.orders[0].transaction;
		}
		const statusNotConfirmed = [0, 9, 99, 100, 101];

		return (
			<div className=" App">
				<div className="header-logo">
					<div className="container">
						<div className="d-flex justify-content-between">
							<a href="https://shopstar.pe/">
								<img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
							</a>
							<span>Ll&aacute;manos al (01) 679-7700</span>
						</div>
					</div>
				</div>
				{
					order.loading &&
					<div className="text-center pt-5	">
						<i className="fas fa-spinner fa-pulse main-text d-block fa-3x mb-4"></i>
						<h4 className="text-center">Cargando informaci&oacute;n de la orden</h4>
					</div>
				}
				{
					(error || order.error) &&
					<div className="text-center pt-5 mt-5">
						<i className="fas fa-times-circle d-block text-danger fa-3x mb-4"></i>
						<h4 className="text-center">Lo sentimos, hubo un error al cargar los datos de tu orden</h4>
					</div>
				}
				{
					(!order.loading && order.data) &&
					<div className="checkout-success">
						<div className="checkout-success__head">
							<div className="container">
								{/* <img src="/img/check.svg" alt="" /> */}
								{/* Other PM */}
								{
									(order.data.orders[0].paymentOption !== 'izipay-qr' && order.data.orders[0].paymentOption !== 'pago-efectivo') &&
									<React.Fragment>
										{
											(order.data.orders[0].orderStatusName === 'New') &&
											<React.Fragment>
												<h2>
													HEMOS RECIBIDO TU SOLICITUD
												</h2>
												<p>
													{/* <span className="d-block">Fecha de compra: {this.getOrderDate(order.data)}</span> */}
													<strong>¡Te llegar&aacute; a tu correo electr&oacute;nico un mensaje comprobando tu solicitud de compra!</strong>
												</p>
												<p>Puedes seguir tu pedido con el c&oacute;digo que acabamos de generar para ti.</p>
												<p>
													Este es el c&oacute;digo de tu pedido
													<b className="rounded-pill">{match.params.id}</b>
												</p>
											</React.Fragment>
										}
										{
											(order.data.orders[0].orderStatusName === 'Confirmed' || order.data.orders[0].orderStatusName === 'ReadyToPickup' || order.data.orders[0].orderStatusName === 'Invoiced') &&
											<React.Fragment>
												<h2>
													HEMOS CONFIRMADO TU PEDIDO
												</h2>
												<p>
													<strong>¡Tu compra ha sido confirmada!.</strong> La tienda empezar&aacute; a preparar tu pedido y
													te avisaremos <br />cuando est&eacute; en camino a tu domicilio. ¡Nos vemos pronto!.
												</p>
												<p>
													Este es el c&oacute;digo de tu pedido
													<b className="rounded-pill">{match.params.id}</b>
												</p>
											</React.Fragment>
										}
										{
											(order.data.orders[0].orderStatusName === 'InTransit' || order.data.orders[0].orderStatus == 5 || order.data.orders[0].orderStatusName === 'InProgress') &&
											<React.Fragment>
												<h2>
													TU PEDIDO ESTA EN CAMINO
												</h2>
												<p>
													<strong>¡Buenas noticias! Tu pedido ya est&aacute; en ruta y pronto lo tendr&aacute;s contigo.</strong>
												</p>
												<p>
													Este es el c&oacute;digo de tu pedido
													<b className="rounded-pill">{match.params.id}</b>
												</p>
											</React.Fragment>
										}
										{
											(order.data.orders[0].orderStatusName === 'Delivered') &&
											<React.Fragment>
												<h2>
													TU PEDIDO HA SIDO ENTREGADO
												</h2>
												<p>
													<strong>¡Buena noticia! Tu pedido ha sido entregado con &eacute;xito.</strong>
												</p>
												<p>
													Este es el c&oacute;digo de tu pedido
													<b className="rounded-pill">{match.params.id}</b>
												</p>
											</React.Fragment>
										}
										{
											(order.data.orders[0].orderStatusName === 'Cancelled' || order.data.orders[0].orderStatusName === 'Rejected' || order.data.orders[0].orderStatusName === 'Invalid') &&
											<React.Fragment>
												<h2>
													TU PEDIDO HA SIDO CANCELADO.
												</h2>
												{
													(order.data.orders[0].installments > 1) &&
													<p>
														<strong>!Oh no¡, lo sentimos,</strong> tu orden fue cancelada porque hubo un problema al procesar tu pago.
														<br />Por favor vuelve a intentar tu compra.
													</p>
												}
												{
													(order.data.orders[0].installments <= 1) &&
													<p>
														<strong>¡Oh no!, lo sentimos,</strong> tu pedido ha sido cancelado.
														<br />Por favor vuelve a intentar tu compra.
													</p>
												}
												<p>

												</p>
											</React.Fragment>
										}
									</React.Fragment>
								}
								{/* QR */}
								{
									(order.data.orders[0].paymentOption === 'izipay-qr') &&
									<React.Fragment>
										{
											(approved || order.data.orders[0].orderStatusName === 'Confirmed') &&
											<React.Fragment>
												<h2>
													TU PEDIDO HA SIDO REALIZADO
												</h2>
												<p>
													<span className="d-block">Fecha de compra: {this.getOrderDate(order.data)}</span>
													Este es el c&oacute;digo de tu pedido
													<b>{match.params.id}</b>
												</p>
											</React.Fragment>
										}
										{
											(!approved && order.data.orders[0].orderStatusName === 'New') &&
											<React.Fragment>
												<h2>
													ESTAMOS A UN PASO DE FINALIZAR TU PEDIDO
												</h2>
												<p>
													<span className="d-block">Tu pedido est&aacute; en proceso, por favor paga con QR para completar tu compra.</span>
												</p>
											</React.Fragment>
										}
										{
											((order.data.orders[0].orderStatusName === 'Rejected' || order.data.orders[0].orderStatusName === 'Invalid' || order.data.orders[0].orderStatusName === 'Cancelled')) &&
											<React.Fragment>
												<h2>
													TU PEDIDO HA SIDO CANCELADO.
												</h2>
												<p>
													<strong>¡Oh no!, lo sentimos,</strong> tu pedido ha sido cancelado debido a que<br /> no realizaste el pago correspondiente.
													Por favor vuelve a intentar tu compra.
												</p>
											</React.Fragment>
										}
										{/* {
										(!approved && order.data.orders[0].orderStatusName === 'Invalid') &&
										<React.Fragment>
											<h2>
												Tu orden ha sido cancelada
											</h2>
											<p>
												<span className="d-block">Lo sentimos, tu orden ha sido cancelada por no completar el pago.</span>
											</p>
										</React.Fragment>
									} */}
									</React.Fragment>
								}
								{/* Pago Efectivo */}
								{
									(order.data.orders[0].paymentOption === 'pago-efectivo') &&
									<React.Fragment>
										{
											(approved || order.data.orders[0].orderStatusName === 'Confirmed') &&
											<React.Fragment>
												<h2>
													TU PEDIDO HA SIDO REALIZADO
												</h2>
												<p>
													<span className="d-block">Fecha de compra: {this.getOrderDate(order.data)}</span>
													Este es el c&oacute;digo de tu pedido
													<b>{match.params.id}</b>
												</p>
											</React.Fragment>
										}
										{
											(!approved && order.data.orders[0].orderStatusName === 'New') &&
											<React.Fragment>
												<h2>
													ESTAMOS A UN PASO DE FINALIZAR TU PEDIDO
												</h2>
												<p>
													<span className="d-block">Tu pedido est&aacute; en proceso, por favor realiza el pago con CIP para completar tu compra.</span>
												</p>
											</React.Fragment>
										}
										{
											((order.data.orders[0].orderStatusName === 'Rejected' || order.data.orders[0].orderStatusName === 'Invalid' || order.data.orders[0].orderStatusName === 'Cancelled')) &&
											<React.Fragment>
												<h2>
													TU PEDIDO HA SIDO CANCELADO.
												</h2>
												<p>
													<strong>¡Oh no!, lo sentimos,</strong> tu pedido ha sido cancelado debido a que<br /> no realizaste el pago correspondiente.
													Por favor vuelve a intentar tu compra.
												</p>
											</React.Fragment>
										}
									</React.Fragment>
								}
							</div>
						</div>
						<div className="checkout-success__nav-status">
							<div className="container">
								<div className="d-flex justify-content-between checkout-success__timeline">
									<div className="text-center first-time">
										<p>
											{(order.data.orders[0].orderStatusName === 'Invalid' || (order.data.orders[0].orderStatus == 101 || order.data.orders[0].orderStatus == 99 || order.data.orders[0].orderStatus == 9)) &&
												<span className="rounded-circle">&nbsp;</span>
											}
											{(order.data.orders[0].orderStatusName !== 'Invalid' && (order.data.orders[0].orderStatus !== 101 && order.data.orders[0].orderStatus !== 99 && order.data.orders[0].orderStatus !== 9)) &&
												<img src="/img/check.svg" alt="" />
											}
										</p>
										<p>Pedido <br />registrado</p>
									</div>
									<div className="text-center">
										<p>
											{(order.data.orders[0].orderStatus <= 6 && (order.data.orders[0].orderStatusName == 'Confirmed' || order.data.orders[0].orderStatusName == 'InProgress')) &&
												<img src="/img/check.svg" alt="" />
											}
											{(statusNotConfirmed.includes(order.data.orders[0].orderStatus) && order.data.orders[0].orderStatusName != 'Confirmed') &&
												<span className="rounded-circle">&nbsp;</span>
											}
										</p>
										<p>Pedido <br />confirmado</p>
									</div>
									<div className="text-center">
										<p>
											{(order.data.orders[0].orderStatus == 6 || order.data.orders[0].orderStatus == 5) &&
												<img src="/img/check.svg" alt="" />
											}
											{(order.data.orders[0].orderStatus != 6) &&
												<span className="rounded-circle">&nbsp;</span>
											}
										</p>
										<p>Pedido <br />en camino</p>
									</div>
									<div className="text-center last-time">
										<p>
											{(order.data.orders[0].orderStatus == 6) &&
												<img src="/img/check.svg" alt="" />
											}
											{(order.data.orders[0].orderStatus != 6) &&
												<span className="rounded-circle">&nbsp;</span>
											}
										</p>
										<p>Pedido <br />entregado</p>
									</div>
								</div>
							</div>
						</div>
						<div className="checkout-success__body">
							<div className="container pt-5 pb-5">
								<div className="row">
									<div className="col-md-6 col-12">
										{
											(order.data.orders[0].paymentOption === 'pago-efectivo') &&
											<div className="checkout-success__body__group bg-white p-4">
												<h3>INFORMACI&Oacute;N DE PAGO</h3>
												<iframe src={order.data.orders[0].paymentLink} width="100%" height={'600px'} frameBorder={'0'}></iframe>
											</div>
										}
										{
											(order.data.orders[0].paymentOption === 'izipay-qr' && order.data.orders[0].orderStatusName !== 'Confirmed' && this.isPendingStatus()) &&
											<div className="checkout-success__body__group bg-white">
												{
													(!approved) &&
													<React.Fragment>
														<h3>QR de pago</h3>
														<div id="izi-secure-form" className="checkout-margin-auto"></div>
														<div className="d-flex justify-content-center">
															<CheckoutTimerQR duration={5 * 60 * 1000} />
														</div>
														<div className="mt-4 checkout-success__body__group__qrcontact">
															<p className="text-center"><b><i>Tienes problemas</i></b> con tu lectura de QR, comunícate con nosotros</p>
															<div className="d-flex justify-content-center mt-3">
																<div className="iconContactQR">
																	<IconContact></IconContact>
																</div>
																<div className="txtLabelContactQR">
																	<a href=""> (01) 679-7700</a>
																</div>
															</div>
															<div className="d-flex justify-content-center mt-3">
																<div className="iconContactQR">
																	<IconMail></IconMail>
																</div>
																<div className="txtLabelContactQR">
																	<a href="mailto:hola@shopstar.pe"> hola@shopstar.pe</a>
																</div>
															</div>
														</div>
													</React.Fragment>
												}
												{
													(approved) &&
													<React.Fragment>
														<h3>Informaci&oacute;n del pago</h3>
														<div className="checkout-success__body__group__data">
															<b>Tarjeta</b>
															<span>{this.getBrandName(card.brand)}</span>
														</div>
														<div className="checkout-success__body__group__data">
															<b>N&uacute;mero de tarjeta</b>
															<span>{card.pan}</span>
														</div>
													</React.Fragment>
												}
											</div>
										}
										<div className="checkout-success__body__group bg-white mb-5">
											<h3>RESUMEN DEL PEDIDO</h3>
											<div className="checkout-success__section_bg p-4">
												{/* <h3>Datos de contacto</h3> */}
												<h3>DETALLES DEL ENV&Iacute;O</h3>
												<div className="checkout-success__body__group__data">
													<b>Nombre y Apellido:</b>
													<span>{order.data.client.firstName} {order.data.client.lastName}</span>
												</div>
												<div className="checkout-success__body__group__data">
													<b>DNI:</b>
													<span>{order.data.client.identificationNumber}</span>
												</div>
												<div className="checkout-success__body__group__data">
													<b>Celular:</b>
													<span>{order.data.client.phone}</span>
												</div>
												<div className="checkout-success__body__group__data">
													<b>Dirección de env&iacute;o:</b>
													<span>{order.data.orders[0].address.addressLine + ' ' + order.data.orders[0].address.addressNumber + ', ' + order.data.orders[0].address.district + ', ' + order.data.orders[0].address.province + ', ' + order.data.orders[0].address.department}</span>
												</div>
											</div>
											<div className="checkout-success__section_bg p-4">

												<h3>DETALLES DEL PAGO</h3>
												{
													(showPrices) &&
													<React.Fragment>
														{/* {(order.data.summary.benefitsDiscount < 0) && 
														<div className="checkout-success__body__summary__item">
															<span><b>Millas Utilizadas:</b></span>
															<span className="value ibkPrice">
																{this.formatNumber(order.data.summary.usedMiles)} <span className="hidden-mobile">Millas</span>
															</span>
														</div>
														} */}
														<div className="checkout-success__body__summary__item">
															{/* <span>Costo ({productsNumber} producto{productsNumber > 1 ? 's' : ''})</span> */}
															<span><b>Costo de producto{productsNumber > 1 ? 's' : ''}:</b></span>

															<span className="value">
																{(productsTotal > order.data.summary.subTotal) &&
																	<del>{this.formatMoney(productsTotal)}</del>
																}
																&nbsp;
																{this.formatMoney(order.data.summary.subTotal)}
															</span>
														</div>
														{(order.data.summary.shippingCost > 0) &&
															<div className="checkout-success__body__summary__item">
																<span><b>Costo de env&iacute;o:</b></span>
																<span className="value">
																	{this.formatMoney(order.data.summary.shippingCost)}
																</span>
															</div>
														}
														{(order.data.summary.discount < 0) &&
															<div className="checkout-success__body__summary__item">
																<span><b>Descuento:</b></span>
																<span className="value">
																	{this.formatMoney(order.data.summary.discount * -1)}
																</span>
															</div>
														}
														<div className="checkout-success__body__summary__item">
															<b>Total:</b>
															<b className="value">
																{this.formatMoney(order.data.summary.total)}
															</b>
														</div>
													</React.Fragment>
												}
												{
													(!showPrices) &&
													<React.Fragment>
														<div className="checkout-success__body__summary__item">
															<b>Total</b>
															<b className="value">
																Gratis
															</b>
														</div>
													</React.Fragment>
												}
												{/* Dividelo */}
												{(order.data.orders[0].installments > 1) &&
													<React.Fragment>
														<div className="checkout-success__body__summary__divider"></div>
														<div className="checkout-success__body__summary__item">
															<b>Forma de pago</b>
														</div>
														<div className="checkout-success__body__summary__item">
															<b>Div&iacute;delo</b>
														</div>
														<div className="checkout-success__body__summary__item">
															<span className="value">{order.data.orders[0].installments} cuotas de {this.formatMoney(order.data.orders[0].installmentAmount)} al mes</span>
															{/* <span className="checkout-success__body__summary__item__value">
																{this.getTotalInstallmentsAmount(order.data.orders[0])}
															</span> */}
														</div>
													</React.Fragment>
												}
												{/* MIllas benefit */}
												{(order.data.summary.benefitsDiscount < 0) &&
													<React.Fragment>
														<div className="checkout-success__body__summary__divider"></div>
														<div className="checkout-success__body__summary__item">
															<b>Canjeado:</b>
														</div>
														<div className="checkout-success__body__summary__item">
															<b className="">Millas Benefit Utilizadas:</b>
															<b className="checkout-success__body__summary__item__value value ibkPrice">
																{this.formatNumber(order.data.summary.usedMiles)} <span className="hidden-mobile">Millas</span>
															</b>
														</div>
													</React.Fragment>
												}

												{/* Tarjeta */}
												{
													(showPrices && order.data.orders[0].paymentOption !== 'pago-efectivo' && order.data.orders[0].paymentOption !== 'izipay-qr') &&
													<React.Fragment>
														<div className="checkout-success__body__summary__divider"></div>
														<div className="checkout-success__body__summary__item">
															<b>Pago realizado:</b>
														</div>
														{
															(order.data.orders[0].transaction) &&
															<div className="checkout-success__body__summary__item">
																<b>Tarjeta <span className="text-uppercase card-type value">{order.data.orders[0].transaction.cardType}</span> {this.formatCardNumber(order.data.orders[0].transaction.cardNumber)}</b>
																<b className="checkout-success__body__summary__item__value value ibkPrice">
																	<img width="30" src="https://mercury.vteximg.com.br/arquivos/ibk-checkout-price.png" style={{ 'marginRight': '10px' }} /> {this.formatMoney(order.data.summary.total)}
																</b>
															</div>
														}
													</React.Fragment>
												}

												{/* Pagoefectivo */}
												{
													(showPrices && order.data.orders[0].paymentOption === 'pago-efectivo') &&
													<React.Fragment>
														<div className="checkout-success__body__summary__divider"></div>
														<div className="checkout-success__body__summary__item">
															<b>A pagar</b>
														</div>
														{
															(order.data.orders[0].transaction) &&
															<div className="checkout-success__body__summary__item">
																<b>
																	<img src="/pe/logo.png" height={'44px'} alt="" />
																</b>
																<b className="checkout-success__body__summary__item__value value">
																	{this.formatMoney(order.data.summary.total)}
																</b>
															</div>
														}
													</React.Fragment>
												}

												{/* QR */}
												{
													(showPrices && order.data.orders[0].paymentOption === 'izipay-qr') &&
													<React.Fragment>
														<div className="checkout-success__body__summary__divider"></div>
														<div className="checkout-success__body__summary__item">
															{
																(approved || order.data.orders[0].orderStatusName === 'Confirmed') &&
																<b>Pagado</b>
															}
															{
																(!approved && order.data.orders[0].orderStatusName !== 'Confirmed') &&
																<b>Por Pagar</b>
															}
														</div>
														{
															(order.data.orders[0].transaction) &&
															<div className="checkout-success__body__summary__item">
																<b>QR/Plin</b>
																<b className="checkout-success__body__summary__item__value value" style={{ 'color': 'rgb(0, 159, 227)' }}>
																	<img width="30" src="https://mercury.vteximg.com.br/arquivos/plin-logo-967A4AF583-seeklogo%201.png" style={{ 'marginRight': '10px' }} /> {this.formatMoney(order.data.summary.total)}
																</b>
															</div>
														}
													</React.Fragment>
												}
											</div>
											{(order.data.summary.benefitsDiscount < 0) &&
												<div className="checkout-success__body__summary__info">
													<img src="/img/info.svg" alt="" />
													Te quedan {this.formatNumber(order.data.orders[0].availableMiles)} Millas Benefit para seguir canjeando.
												</div>
											}
										</div>
									</div>
									<div className="col-md-6 col-12">
										{
											order.data.orders.map((orderItem, i) => {
												return (
													<React.Fragment key={i}>
														<div className="checkout-success__body__summary bg-white">
															<div className="checkout-success__section_bg p-4 d-none">

																<h3>DETALLES DEL PAGO</h3>
																<h4>Precios</h4>
																{
																	(showPrices) &&
																	<React.Fragment>
																		<div className="checkout-success__body__summary__item">
																			<span><b>Costo de producto{productsNumber > 1 ? 's' : ''}:</b></span>

																			<span className="value">
																				{(productsTotal > order.data.summary.subTotal) &&
																					<del>{this.formatMoney(productsTotal)}</del>
																				}
																				&nbsp;
																				{this.formatMoney(order.data.summary.subTotal)}
																			</span>
																		</div>
																		{(order.data.summary.shippingCost > 0) &&
																			<div className="checkout-success__body__summary__item">
																				<span><b>Costo de env&iacute;o:</b></span>
																				<span className="value">
																					{this.formatMoney(order.data.summary.shippingCost)}
																				</span>
																			</div>
																		}
																		{(order.data.summary.discount < 0) &&
																			<div className="checkout-success__body__summary__item">
																				<span><b>Descuento:</b></span>
																				<span className="value">
																					{this.formatMoney(order.data.summary.discount * -1)}
																				</span>
																			</div>
																		}
																		<div className="checkout-success__body__summary__item">
																			<b>Total:</b>
																			<b className="value">
																				{this.formatMoney(order.data.summary.total)}
																			</b>
																		</div>
																	</React.Fragment>
																}
																{
																	(!showPrices) &&
																	<React.Fragment>
																		<div className="checkout-success__body__summary__item">
																			<b>Total</b>
																			<b className="value">
																				Gratis
																			</b>
																		</div>
																	</React.Fragment>
																}
																{/* Dividelo */}
																{(order.data.orders[0].installments > 1) &&
																	<React.Fragment>
																		<div className="checkout-success__body__summary__divider"></div>
																		<div className="checkout-success__body__summary__item">
																			<b>Forma de pago</b>
																		</div>
																		<div className="checkout-success__body__summary__item">
																			<b>Div&iacute;delo</b>
																		</div>
																		<div className="checkout-success__body__summary__item">
																			<span className="value">{order.data.orders[0].installments} cuotas de {this.formatMoney(order.data.orders[0].installmentAmount)} al mes</span>
																		</div>
																	</React.Fragment>
																}
																{/* MIllas benefit */}
																{(order.data.summary.benefitsDiscount < 0) &&
																	<React.Fragment>
																		<div className="checkout-success__body__summary__divider"></div>
																		<div className="checkout-success__body__summary__item">
																			<b>Canjeado</b>
																		</div>
																		<div className="checkout-success__body__summary__item">
																			<b>Millas Benefit</b>
																			<b className="checkout-success__body__summary__item__value">
																				{this.formatNumber(order.data.summary.usedMiles)} Millas
																			</b>
																		</div>
																	</React.Fragment>
																}

																{/* Tarjeta */}
																{
																	(showPrices && order.data.orders[0].paymentOption !== 'pago-efectivo' && order.data.orders[0].paymentOption !== 'izipay-qr') &&
																	<React.Fragment>
																		<div className="checkout-success__body__summary__divider"></div>
																		<div className="checkout-success__body__summary__item">
																			<b>Pagado</b>
																		</div>
																		{
																			(order.data.orders[0].transaction) &&
																			<div className="checkout-success__body__summary__item">
																				<b>Tarjeta <span className="text-uppercase card-type value">{order.data.orders[0].transaction.cardType}</span> {this.formatCardNumber(order.data.orders[0].transaction.cardNumber)}</b>
																				<b className="checkout-success__body__summary__item__value value">
																					{this.formatMoney(order.data.summary.total)}
																				</b>
																			</div>
																		}
																		{(order.data.summary.benefitsDiscount < 0) &&
																			< div className="checkout-success__body__summary__info">
																				<img src="/img/info.svg" alt="" />
																				Te quedan {this.formatNumber(order.data.orders[0].availableMiles)} Millas Benefit para seguir canjeando.
																			</div>
																		}
																	</React.Fragment>
																}

																{/* Pagoefectivo */}
																{
																	(showPrices && order.data.orders[0].paymentOption === 'pago-efectivo') &&
																	<React.Fragment>
																		<div className="checkout-success__body__summary__divider"></div>
																		<div className="checkout-success__body__summary__item">
																			<b>A pagar</b>
																		</div>
																		{
																			(order.data.orders[0].transaction) &&
																			<div className="checkout-success__body__summary__item">
																				<b>
																					<img src="/pe/logo.png" height={'44px'} alt="" />
																				</b>
																				<b className="checkout-success__body__summary__item__value value">
																					{this.formatMoney(order.data.summary.total)}
																				</b>
																			</div>
																		}
																	</React.Fragment>
																}

																{/* QR */}
																{
																	(showPrices && order.data.orders[0].paymentOption === 'izipay-qr') &&
																	<React.Fragment>
																		<div className="checkout-success__body__summary__divider"></div>
																		<div className="checkout-success__body__summary__item">
																			{
																				(approved || order.data.orders[0].orderStatusName === 'Confirmed') &&
																				<b>Pagado</b>
																			}
																			{
																				(!approved && order.data.orders[0].orderStatusName !== 'Confirmed') &&
																				<b>Por Pagar</b>
																			}
																		</div>
																		{
																			(order.data.orders[0].transaction) &&
																			<div className="checkout-success__body__summary__item">
																				<b>QR/Plin</b>
																				<b className="checkout-success__body__summary__item__value value">
																					{this.formatMoney(order.data.summary.total)}
																				</b>
																			</div>
																		}
																	</React.Fragment>
																}
															</div>
															{
																(orderItem.seller.name !== '1') &&
																<React.Fragment>
																	{
																		(orderItem.orderStatusName === 'Rejected' || orderItem.orderStatusName === 'Invalid' || orderItem.orderStatusName === 'Cancelled') &&
																		<h3 className="">ART&Iacute;CULO{productsNumber > 1 ? 'S' : ''} CANCELADO{productsNumber > 1 ? 'S' : ''}</h3>
																	}
																	{
																		(orderItem.orderStatusName !== 'Rejected' && orderItem.orderStatusName !== 'Invalid' && orderItem.orderStatusName !== 'Cancelled') &&
																		<h3 className="">ART&Iacute;CULO{productsNumber > 1 ? 'S' : ''} SELECCIONADO{productsNumber > 1 ? 'S' : ''}</h3>
																	}
																</React.Fragment>
															}
															{
																(orderItem.seller.name === '1') &&
																<React.Fragment>
																	<h3 className="">GARANT&Iacute;A{productsNumber > 1 ? 'S' : ''} SELECCIONADA{productsNumber > 1 ? 'S' : ''}</h3>
																</React.Fragment>
															}
															<div className="checkout-success__body__store">
																{
																	(orderItem.seller.name !== '1') &&
																	<React.Fragment>
																		<div className="checkout-success__body__store__head">
																			{
																				(orderItem.orderStatusName !== 'Rejected' && orderItem.orderStatusName !== 'Invalid' && orderItem.orderStatusName !== 'Cancelled') &&
																				<React.Fragment>
																					{
																						(orderItem.isPickUp) &&
																						<span className="shipping-type pickup">Recojo en tienda</span>
																					}
																					{
																						(!orderItem.isPickUp) &&
																						<span className="shipping-type delivery">Env&iacute;o a domicilio</span>
																					}
																				</React.Fragment>
																			}
																			{
																				(orderItem.orderStatusName === 'Rejected' || orderItem.orderStatusName === 'Invalid' || orderItem.orderStatusName === 'Cancelled') &&
																				<React.Fragment>
																					<span className="shipping-type cancelled">Cancelado</span>
																				</React.Fragment>
																			}
																		</div>

																		<div className="checkout-success__body__store__head2">
																			<p className={this.getOrderItemCancelled(orderItem) ? "d-block text-strike" : "d-block"}>
																				{(orderItem.isPickUp) &&
																					<b>Fecha de recojo: </b>
																				}
																				{(!orderItem.isPickUp) &&
																					<b>Fecha de entrega: </b>
																				}
																				<span>{this.showEstimatedDate(orderItem)}</span>
																			</p>
																			{(orderItem.isPickUp && orderItem.storePickUp) &&
																				<p className={this.getOrderItemCancelled(orderItem) ? "d-block text-strike" : "d-block"}>
																					<b>Direcci&oacute;n de recojo: </b>
																					<span>{orderItem.storePickUp.addressLine} </span>
																					<span>{orderItem.storePickUp.district}, {orderItem.storePickUp.province}, {orderItem.storePickUp.department}</span>
																				</p>
																			}
																			{(!orderItem.isPickUp) &&
																				<p className={this.getOrderItemCancelled(orderItem) ? "d-block text-strike" : "d-block"}>
																					<b>Direcci&oacute;n de env&iacute;o: </b>
																					<span>{order.data.orders[0].address.addressLine} {order.data.orders[0].address.addressNumber} </span>
																					<span>{order.data.orders[0].address.district}, {order.data.orders[0].address.province}, {order.data.orders[0].address.department}</span>
																				</p>
																			}
																			{(orderItem.isPickUp && !orderItem.storePickUp) &&
																				<p className={this.getOrderItemCancelled(orderItem) ? "d-block text-strike" : "d-block"}>
																					<b>Tienda: </b>
																					<span>{orderItem.seller.name}</span>
																				</p>
																			}
																		</div>
																		{
																			(orderItem.seller.name === 'oechsle' && orderItem.isPickUp) &&
																			<div className="checkout-success__body__store__help-text">
																				<img src="/img/info.svg" alt="" />
																				Recuerda que tendr&aacute;s 7 d&iacute;as a partir de la fecha seleccionada para recoger tu pedido; luego de ese plazo, el pedido se anular&aacute;.
																			</div>
																		}
																	</React.Fragment>
																}
																<div className="checkout-success__body__store__info">
																	{
																		orderItem.items.map((item, j) => {
																			return (
																				<React.Fragment key={i + '-' + j}>
																					<div className="checkout-success__body__store__info__products">
																						<div className="checkout-success__body__store__info__products__item">
																							<div className="checkout-success__body__store__info__products__item__image">
																								<img src={this.setHttps(item.productImage)} alt="" />
																							</div>
																							<div className="checkout-success__body__store__info__products__item__details">
																								<div className="name">{item.productName}</div>
																								<div className="seller">{orderItem.seller.name}</div>
																								{
																									(showPrices) &&
																									<div className="price justify-content-between">
																										{(item.basePrice > item.price) &&
																											<React.Fragment>
																												<div className="base-price">{this.formatMoney(item.basePrice)}</div>
																												{(order.data.orders[0].paymentOption == 'izipay-qr') &&
																													<div className="special-price" style={{ 'color': 'rgb(0, 159, 227)' }}>
																														<img width="30" src="https://mercury.vteximg.com.br/arquivos/plin-logo-967A4AF583-seeklogo%201.png" style={{ 'marginRight': '10px' }} />{this.formatMoney(item.price)}
																													</div>
																												}
																												{(order.data.orders[0].paymentOption !== 'izipay-qr') &&
																													<div className="special-price">{this.formatMoney(item.price)}</div>
																												}
																											</React.Fragment>
																										}
																										{(item.basePrice == item.price) &&
																											<React.Fragment>
																												<div className="special-price">{this.formatMoney(item.price)}</div>
																											</React.Fragment>
																										}
																										{/* <div className="special-price">
																												{this.formatMoney(item.price)}
																											</div> */}
																									</div>
																								}
																								<div className="quantity">
																									Cantidad: {item.quantity}
																								</div>
																							</div>
																						</div>
																					</div>
																				</React.Fragment>
																			)
																		})
																	}

																</div>
																<div className="checkout-success__body__store__shipping d-none">
																	{(orderItem.isPickUp && orderItem.storePickUp) &&
																		<div className="checkout-success__body__store__shipping__data">
																			<b>{orderItem.storePickUp.branchName}</b>
																			<span>{orderItem.storePickUp.addressLine}</span>
																			<span>{orderItem.storePickUp.district}, {orderItem.storePickUp.province}, {orderItem.storePickUp.department}</span>
																		</div>
																	}
																	{(!orderItem.isPickUp) &&
																		<div className="checkout-success__body__store__shipping__data">
																			<b>Dirección de env&iacute;o</b>
																			<span>{order.data.orders[0].address.addressLine} {order.data.orders[0].address.addressNumber}</span>
																			<span>{order.data.orders[0].address.district}, {order.data.orders[0].address.province}, {order.data.orders[0].address.department}</span>
																		</div>
																	}
																	<div className="checkout-success__body__store__shipping__data">
																		{(!orderItem.isPickUp) &&
																			<b>Fecha de entrega</b>
																		}
																		{(orderItem.isPickUp) &&
																			<b>Fecha de recojo</b>
																		}
																		<span>{this.showEstimatedDate(orderItem)}</span>
																	</div>
																	{(orderItem.deliveryWindowStart && orderItem.deliveryWindowStart !== '') &&
																		<div className="checkout-success__body__store__shipping__data">
																			<b>Rango Horario</b>
																			<span>{this.showRangeTime(orderItem)}</span>
																		</div>
																	}
																</div>
																{
																	(orderItem.isPickUp) &&
																	<div className="checkout-success__body__store__help-text d-none">
																		<img src="/img/info.svg" alt="" />
																		El titular de la compra ({order.data.client.firstName} {order.data.client.lastName}) debe acercarse a la tienda con su documento de identidad para retirar el producto.
																	</div>
																}
															</div>
														</div>
													</React.Fragment>
												)
											})
										}
										{
											this.renderMessageOnlyPickup(order.data)
										}
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 text-center mb-5">
										<a href="https://shopstar.pe/" className="btn btn-main px-5 fa-2x">Volver a comprar</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				}

				<div id="footer">
					<div className="center-wrap">
						<div className="row">
							<div className="col -w1">
								<ul className="mdpg items">
									<li className="item title-card-footer mr-3">

										<i className="mr-2">
											<img src="https://shopstar.pe/arquivos/pagos-gris.png" alt="Shopstar Checkout" width="30" />
										</i>
										<span>MEDIOS DE PAGO:</span></li>

									<li className="itemicono-card-gris mr-2">
										<i>
											<img src="https://shopstar.pe/arquivos/card-visa.png" alt="Shopstar Checkout" width="50" />
										</i>
									</li>
									<li className="item icono-card-gris mr-2">
										<i>
											<img src="https://shopstar.pe/arquivos/card-mastercard.png" alt="Shopstar Checkout" width="50" />
										</i>
									</li>
									<li className="item icono-card-gris">
										<i>
											<img src="https://shopstar.pe/arquivos/card-americanexpress.png" alt="Shopstar Checkout" width="50" />
										</i>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade payment-modal  loading-modal" id="errorModal" role="dialog" aria-labelledby="milesModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-body">
								<div className="header">
									<a href="https://shopstar.pe">
										<img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
									</a>
								</div>
								<img src="/alert-3d.png" alt="" width="150px" />
								<div className="payment-alert">
									{error}
								</div>
								<button className="btn btn-main" type="button" onClick={(e) => { this.toHome() }}>Volver a comprar</button>
							</div>
						</div>
					</div>
				</div>
			</div >
		);
	}
}

export default CheckoutSuccessView;
